import React, {useRef, useState} from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    CloseButton,
    Box,
    HStack,
    InputGroup,
    InputRightElement,
    Link,
    Flex,
    VStack, useToast, Textarea, ModalFooter, List, ListItem, ListIcon, Checkbox
} from "@chakra-ui/react";
import {setCurrentChatInput} from "../../../redux/reducers";
import {FaCheckCircle} from "react-icons/fa";
import lightTheme from "../../../utils/styles";

const GeneratePromptScreen1 = ({ isOpenGeneratePromptScreen1, onCloseGeneratePromptScreen1 }) => {
    const [prompt, setPrompt] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const [isInputFocused, setIsInputFocused] = useState(false);
    const textareaRef = useRef(null);
    const [wordCount, setWordCount] = useState("");

    const [promptStrength, setPromptStrength] = useState('Weak prompt: add more context for higher quality generations');

    const determinePromptStrength = (prompt) => {
        // Implement your logic here. This is a simple placeholder.
        const length = prompt.length;
        if (length < 50) return 'Weak prompt: add more context for higher quality generations';
        if (length >= 50 && length < 100) return 'Average prompt: consider including important keywords';
        return 'Great prompt: Jenni will reference this when generating text';
    };

    const handleInputChange = (e) => {
        const newPrompt = e.target.value;
        setPrompt(e.target.value);
        setPromptStrength(determinePromptStrength(newPrompt));
    };

    const startGeneration = async () => {
        setIsLoading(true)

        setIsLoading(false)
    };

    const PromptStrengthBar = () => {
        // Define the styles for different strengths
        const styles = {
            W: { width: '33%', backgroundColor: 'red' },
            A: { width: '66%', backgroundColor: 'red' },
            G: { width: '100%', backgroundColor: 'red' },
        };

        return (
            <div style={{
                height: '3px',
                backgroundColor: 'lightgray',
                borderRadius: '2px',
                marginTop: '10px',
            }}>
                <div style={{
                    ...styles[promptStrength.charAt(0)],
                    transition: 'width 0.5s ease-in-out', // Smooth transition for width change
                    height: '100%',
                    borderRadius: '2px',
                }}></div>
            </div>
        );
    };

    return (
        <>
            <Modal isOpen={false} onClose={onCloseGeneratePromptScreen1} isCentered size="xl">
                <ModalOverlay />
                <ModalContent borderRadius="1rem" p="0.75rem" h="37.5rem" w="35rem" overflowY="auto" position="relative">
                    <Box
                        position="absolute"
                        top="10px"
                        right="10px"
                        zIndex={999}
                    >
                        <CloseButton
                            size="md"
                            onClick={onCloseGeneratePromptScreen1}
                            bg="transparent"
                            borderRadius="50%"
                            _hover={{
                                bg: "gray.200",
                                borderRadius: "50%",
                            }}
                        />
                    </Box>
                    <Flex alignItems="center" justifyContent="space-between" p={4} >
                        <VStack align="center" width={'100%'} spacing={6}>
                            <Text fontSize="lg" fontWeight="bold">What are you writing today?</Text>
                            {/*<GoogleButton />*/}
                            {/*<HStack width={'100%'}>*/}
                            {/*    <Box w={'full'} h={'1px'} bg={'gray.300'} />*/}
                            {/*    <Text fontSize={'sm'} color={'gray.500'}>or</Text>*/}
                            {/*    <Box w={'full'} h={'1px'} bg={'gray.300'} />*/}
                            {/*</HStack>*/}
                        </VStack>
                    </Flex>
                    <ModalBody>
                        <Stack spacing={4} mt={0}>
                            <FormControl id="prompt" isRequired>
                                {/*<FormLabel>Your Prompt</FormLabel>*/}
                                <Textarea
                                    name="message-input"
                                    ref={textareaRef}
                                    placeholder={"e.g. Write an essay about global warming"}
                                    value={prompt}
                                    // style={{ border: 'none', borderRight:'none', borderRightColor:"white", marginRight: 0,
                                    //     borderTopWidth: isAskVisible || isFollowUpVisible ? '0px' : '1px',
                                    //     borderTopLeftRadius: isAskVisible || isFollowUpVisible ? '0px' : '10px',
                                    //     borderTopRightRadius: isAskVisible || isFollowUpVisible ? '0px' : '10px'}}
                                    // _focus={{ boxShadow: 'none', outline: 'none' }}
                                    onChange={handleInputChange}
                                    minH={'8rem'}
                                    onFocus={() => setIsInputFocused(true)}
                                    onBlur={() => setIsInputFocused(false)}
                                    overflowY="auto"  // Allow vertical scrollbar
                                    maxH="15rem"
                                    resize="none"  // Disable manual resizing
                                    onInput={(e) => {  // Auto-expand as text overflows
                                        e.currentTarget.style.height = 'auto';
                                        e.currentTarget.style.height = (e.currentTarget.scrollHeight) + 'px';
                                    }}
                                    required
                                    width={'100%'}
                                />
                                <PromptStrengthBar />
                                <Text fontSize={'sm'} mt={2}>{promptStrength}</Text>
                            </FormControl>
                            {/*<Stack pt={6}>*/}
                            {/*    <Text align={'center'}>*/}
                            {/*        Don't have an account? <Link color={'blue.400'} onClick={()=>{*/}

                            {/*    }}>Sign Up</Link>*/}
                            {/*    </Text>*/}
                            {/*</Stack>*/}
                            <VStack align="flex-start" width={'100%'} spacing={10} mt={'1rem'}>
                                <List spacing={3} textAlign="start">
                                    <ListItem key={1}>
                                        <Flex align="start">
                                            <ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={3} />
                                            <Text fontSize={'sm'}>Type or paste in a prompt to generate any long-form text you want.</Text>
                                        </Flex>
                                    </ListItem>
                                    <ListItem key={2}>
                                        <Flex align="start">
                                            <ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={3} />
                                            <Text fontSize={"sm"}>After the initial generation, you can edit it yourself and then ask for more improvements/refinements.</Text>
                                        </Flex>
                                    </ListItem>
                                    <ListItem key={2}>
                                        <Flex align="start">
                                            <ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={3} />
                                            <Text fontSize={"sm"}>Generate, Refine/Revise, Finished Work</Text>
                                        </Flex>
                                    </ListItem>
                                </List>
                                <VStack align="flex-start" width={'100%'} spacing={3}>
                                    <Box display="flex" alignItems="center">
                                        <Checkbox id="nice-checkbox" colorScheme="green" defaultIsChecked>
                                            {/* The Checkbox component can accept children that will be rendered as its label */}
                                        </Checkbox>
                                        <Text ml={3} htmlFor="nice-checkbox" fontSize={'sm'}>
                                            Reference your uploaded sources to generate text.
                                        </Text>
                                    </Box>

                                    <VStack justify={'center'} width={'17%'}>
                                        <Text fontSize={"xs"} fontWeight={'bold'}>Word Count</Text>
                                        <Input
                                            placeholder=""
                                            fontSize={"sm"}
                                            size={"sm"}
                                            backgroundColor={lightTheme.colors.white}
                                            width={"3.95rem"}
                                            type="text" // Change this to text to use maxLength
                                            maxLength={4} // Ensures no more than 4 characters
                                            inputMode="numeric" // Brings up the numeric keypad on mobile devices
                                            pattern="[0-9]*"
                                            min={100}
                                            max={2000}
                                            value={wordCount}
                                            onChange={(e) => {
                                                const val = e.target.value;
                                                // Check if the value is numeric and doesn't exceed 4 digits
                                                if (val.length <= 4 && /^\d*$/.test(val)) {
                                                    setWordCount(val);
                                                }
                                            }}
                                        />
                                    </VStack>
                                </VStack>
                            </VStack>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Flex width="100%" justify="center">
                            <Button
                                loadingText="Submitting"
                                size="lg"
                                isLoading={isLoading}
                                onClick={startGeneration}
                                bgGradient={'linear(to-r, red.400,pink.400)'}
                                color={'white'}
                                width={'55%'}
                                _hover={{
                                    boxShadow: 'xl',
                                }}
                            >
                                Start Generation
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default GeneratePromptScreen1;








