import React, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    CloseButton,
    Box,
    HStack,
    InputGroup,
    InputRightElement,
    Link,
    Flex,
    VStack, useToast, Image, ListItem, ListIcon, List, ModalFooter
} from "@chakra-ui/react";
import {FaCheckCircle} from "react-icons/fa";
import {FcRight} from "react-icons/fc";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {SERVER_IP} from "../../../utils/constants";
import {
    addDocument,
    removeDocument,
    setCeleryTaskID, setIsPricingModalOpen,
    setIsScanActuallyInProgress,
    setScanInProgress
} from "../../../redux/reducers";
import {addDocumentInStorage, getDocumentsInStorage} from "../../../utils/local-storage-helpers";
import {useAuth} from "../../../redux/AuthProvider";
import {StarIcon} from "@chakra-ui/icons";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const PricingScreen = ({ isOpenPricingScreen, onClosePricingScreen, onOpenPricingScreen }) => {
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const scanEstimatedSeconds = useSelector(state => state.settings.scanEstimatedSeconds)
    const scanFileID = useSelector(state => state.settings.scanFileID)
    const dispatch = useDispatch()
    const [intervalHook, setIntervalHook] = useState(0)
    const auth = useAuth();
    const navigate = useNavigate();
    const isPricingModalOpen = useSelector(state => state.settings.isPricingModalOpen)
    const {t} = useTranslation();

    const closeModal = () => {
        onClosePricingScreen();
        dispatch(setIsPricingModalOpen(false))
    }

    return (
        <>
            <Modal isOpen={isPricingModalOpen} onClose={closeModal} isCentered size="xl">
                <ModalOverlay />
                <ModalContent borderRadius="1rem" p="0.75rem" h="35rem" w="25rem" overflowY="auto" position="relative">
                    <Box
                        position="absolute"
                        top="10px"
                        right="10px"
                        zIndex={999}
                    >
                        <CloseButton
                            size="md"
                            onClick={() => {
                                onClosePricingScreen();
                                dispatch(setIsPricingModalOpen(false))
                            }}
                            bg="transparent"
                            borderRadius="50%"
                            _hover={{
                                bg: "gray.200",
                                borderRadius: "50%",
                            }}
                        />
                    </Box>
                    <Flex alignItems="center" justifyContent="space-between" p={4} >
                        <VStack align="center" width={'100%'} spacing={6}>
                            <Text fontSize="1.18rem" fontWeight={'600'}>{t("(Free Plan) Limit Exceeded")}</Text>
                            <Box w={'full'} h={'1px'} bg={'gray.300'} />
                            <VStack align="center" width={'100%'} spacing={6}>
                                <Text fontSize={'md'} fontWeight={'600'}>{t("For the price of a Chipotle burrito, get:")}</Text>
                                <List spacing={2} textAlign="start" justify={'flex-start'}>
                                    <ListItem key={1}>
                                        <Flex align="start">
                                            <ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={3} />
                                            <Text fontSize={"md"} fontWeight={'500'}>{t("Unlimited chats.")}</Text>
                                        </Flex>
                                    </ListItem>
                                    <ListItem key={2}>
                                        <Flex align="start">
                                            <ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={3} />
                                            <Text fontSize={"md"} fontWeight={'500'}>{t("Unlimited PDF/video uploads.")}</Text>
                                        </Flex>
                                    </ListItem>
                                    <ListItem key={3}>
                                        <Flex align="start">
                                            <ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={3} />
                                            <Text fontSize={"md"} fontWeight={'500'}>{t("Up to 200 pages for scanned PDFs.")}</Text>
                                        </Flex>
                                    </ListItem>
                                </List>
                            </VStack>
                        </VStack>
                    </Flex>
                    <ModalBody>
                        <Flex justify="center" align="center" p={0} position="relative" mt={4}>
                            <Image
                                src="/dancing_bear.gif" // Replace this with the actual URL of your GIF
                                alt="Dancing Bear"
                                boxSize="150px"
                                mr={24}
                                mt={6}
                            />
                            {/* Thought bubble with circles */}
                            <Flex
                                direction="column"
                                alignItems="flex-start"
                                position="absolute"
                                right="31.5"
                                top="-7px"
                                transform="translateX(5%)"
                            >
                                {/* Thought bubble */}
                                <Box
                                    bg="white"
                                    p="2"
                                    px={4}
                                    borderRadius={100}
                                    borderWidth="1px"
                                    borderColor="gray.300"
                                    boxShadow="sm"
                                    fontSize="sm"
                                    textAlign="center"
                                    width="auto"
                                >
                                    {t("keep me happy!")}
                                </Box>
                                <Box w="10px" h="10px" bg="white" borderWidth={1} borderRadius="full"
                                     mb="1" boxShadow="sm" borderColor={'gray'} mt={1} ml={-1}/>
                                <Box w="8px" h="8px" bg="white" borderWidth={1} borderRadius="full"
                                     mb="1" ml={-3} boxShadow="sm" borderColor={'gray'}/>
                                {/*<Box w="6px" h="6px" bg="white" borderWidth={1} borderRadius="full"*/}
                                {/*     mb="1" ml={-4} boxShadow="sm" borderColor={'black'}/>*/}
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Flex width="100%" justify="center">
                            <Button
                                loadingText="Submitting"
                                size="md"
                                isLoading={isLoading}
                                width={'55%'}
                                onClick={() => {
                                    onClosePricingScreen();
                                    dispatch(setIsPricingModalOpen(false))
                                    navigate('/pricing');
                                }}
                                bgGradient="linear(to-r, red.400,pink.400)"
                                color={'white'}
                                _hover={{
                                    bgGradient: 'linear(to-r, red.400,pink.400)',
                                    boxShadow: 'xl',
                                }}>
                                {t("View Pricing")}
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default PricingScreen;








