import {Box} from "@chakra-ui/react";

export default function CustomPlusIcon({color}) {
    return (
        <Box
            position="relative"
            height="16rem"
            width="12.3rem"
            borderWidth="0px"
            borderColor="lightgray"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            {/* Horizontal Line */}
            <Box
                position="absolute"
                height="0.65rem" // Adjust the thickness of the plus sign
                width="4rem"
                bgGradient={'linear(to-r, red.400,pink.400)'}
                // backgroundColor={color} // Adjust the color of the plus sign
            />
            {/* Vertical Line */}
            <Box
                position="absolute"
                width="0.65rem" // Adjust the thickness of the plus sign
                height="4rem"
                bgGradient={'linear(to-r, red.400,pink.400)'}
                // backgroundColor={color} // Adjust the color of the plus sign
            />
        </Box>
    );
}
