import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    VStack,
    HStack,
    Flex,
    Text,
    useColorModeValue,
    IconButton,
    Textarea,
    Button,
    Heading,
    UnorderedList,
    OrderedList,
    ListItem,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Image,
    useDisclosure,
    useToast, ListIcon, List, Divider, Icon, Badge,
} from '@chakra-ui/react';
import {useDispatch, useSelector} from "react-redux";
import {MessageBubble} from "./MessageBubble";
import {useAuth} from "../../redux/AuthProvider";
import lightTheme from "../../utils/styles";
import {FaBars, FaCheckCircle} from "react-icons/fa";
import {AiOutlineArrowRight, AiOutlineSend} from "react-icons/ai";
import {RiCloseFill} from "react-icons/ri";
import {
    setAskContent, setAskImageContent,
    setCurrentChatInput, setFollowUpContent, setIsPricingModalOpen, toggleIsAskImageVisibleFalse,
    toggleIsAskVisibleFalse, toggleIsFollowUpVisibleFalse,
    toggleIsFollowUpVisibleTrue
} from "../../redux/reducers";
import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";
import {BiStopCircle} from "react-icons/bi";
import {markdownComponents} from "../../utils/constants";
import SignUpScreen1 from "../Modals/SignUp/SignUpScreen1";
import SignInScreen1 from "../Modals/SignIn/SignInScreen1";
import {CheckCircleIcon} from "@chakra-ui/icons";
import { GrChatOption } from "react-icons/gr";
import {FcComments} from "react-icons/fc";
import {initializeGeneration} from "../../utils/pdf-page-helpers";
import {useNavigate} from "react-router-dom";
import PricingScreen from "../Modals/Payments/PricingScreen";
import {useTranslation} from "react-i18next";

export const Chat = ({ theDocument, handleNewMessage, handleChatResponseNoSelection, sessionToken, socketID,
                         stopClaudeResponse }) => {
    const lastMessageRef = useRef(null);
    const userBgColor = useColorModeValue("#000", "#fff");
    const claudeBgColor = useColorModeValue(lightTheme.colors.lightGray, "#2e93ea");
    const claudeColor = useColorModeValue("#000000", "#E2E8F0");
    const currentChatInput = useSelector(state => state.settings.currentChatInput);

    const { isTyping, setIsTyping } = useAuth();
    const {isThinking, setIsThinking} = useAuth();
    const documents = useSelector(state => state.documents.savedDocuments);
    const currentMessages = documents.find(doc => doc.id === theDocument.id).messages;
    const [isInputFocused, setIsInputFocused] = useState(false);
    const isAskVisible = useSelector(state => state.settings.isAskVisible);
    const isAskImageVisible = useSelector(state => state.settings.isAskImageVisible);
    const isFollowUpVisible = useSelector(state => state.settings.isFollowUpVisible);
    const askContent = useSelector(state => state.settings.askContent);
    const askImageContent = useSelector(state => state.settings.askImageContent);
    const followUpContent = useSelector(state => state.settings.followUpContent);
    const dispatch = useDispatch();
    const textareaRef = useRef(null);
    const { isOpen: isOpenSignUpScreen1, onOpen: onOpenSignUpScreen1, onClose: onCloseSignUpScreen1 } = useDisclosure();
    const { isOpen: isOpenSignInScreen1, onOpen: onOpenSignInScreen1, onClose: onCloseSignInScreen1 } = useDisclosure();
    const toast = useToast();
    const auth = useAuth();
    const navigate = useNavigate();
    const isProPlan = useSelector((state) => state.settings.isProPlan);
    const { isOpen: isOpenPricingScreen, onOpen: onOpenPricingScreen, onClose: onClosePricingScreen } = useDisclosure();
    const {t} = useTranslation();

    const modifiedHandleNewMessage = () => {
        // if (currentChatInput === 'pricing') {
        //     dispatch(setIsPricingModalOpen(true));
        //     dispatch(setCurrentChatInput(''));
        //     if (textareaRef && textareaRef.current) {
        //         textareaRef.current.style.height = '3.5rem';
        //     }
        //     return;
        // }

        if (!JSON.parse(localStorage.getItem("isProPlan")) && localStorage.getItem("isChatExceeded") === "true") {
            toast({
                title: t("(Free Plan) Chat Limit Exceeded!"),
                status: "info",
                duration: 9000,
                isClosable: true,
                position: "top",
            });
            dispatch(setIsPricingModalOpen(true));
            dispatch(setCurrentChatInput(''));
            if (textareaRef && textareaRef.current) {
                textareaRef.current.style.height = '3.5rem';
            }
            return;
        }


        if (currentMessages && currentMessages.length >= 3 && localStorage.getItem("userID") === null) {
            toast({
                title: t("You've reached your chat limit in guest mode!"),
                description: t("Please sign up to continue chatting."),
                status: "info",
                duration: 9000,
                isClosable: true,
                position: "top",
            });
            onOpenSignUpScreen1();
            const chatInput = currentChatInput;
            dispatch(setCurrentChatInput(''));
            if (textareaRef && textareaRef.current) {
                textareaRef.current.style.height = '3.5rem';
            }
            return;
        }
        const chatInput = currentChatInput;
        dispatch(setCurrentChatInput(''));
        handleNewMessage(chatInput);
        dispatch(setCurrentChatInput(''));
        if (textareaRef && textareaRef.current) {
            textareaRef.current.style.height = '3.5rem';
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (currentChatInput !== "" && !isTyping && !isThinking) {
                modifiedHandleNewMessage();
            }
        }
    };

    useEffect(() => {
        const scrollMessageIntoView = () => {
            if (lastMessageRef.current) {
                // console.log('Height of last message:', lastMessageRef.current.offsetHeight);
                if (lastMessageRef.current.offsetHeight < 500) {
                    lastMessageRef.current.scrollIntoView({ behavior: 'instant', block: 'end' });
                }
            }
        };
        scrollMessageIntoView();

    }, [currentMessages]);

    useEffect(() => {
        const scrollMessageIntoView = () => {
            if (lastMessageRef.current) {
                lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
        };
        scrollMessageIntoView();

    }, []);

    useEffect(() => {
        if (textareaRef.current && isAskVisible) {
            textareaRef.current.focus();
        }
    }, [isAskVisible]);

    useEffect(() => {
        if (textareaRef.current && isAskImageVisible) {
            textareaRef.current.focus();
        }
    }, [isAskImageVisible]);

    useEffect(() => {
        if (textareaRef.current && isFollowUpVisible) {
            textareaRef.current.focus();
        }
    }, [isFollowUpVisible]);

    const placeholders = ["Write a 500 word discussion post using 3 readings",
        "Write a 600 word essay using this source", "Summarize 5 documents in 400 words"];
    const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholders[0]);
    const [index, setIndex] = useState(0);
    const [fade, setFade] = useState(true); // State to manage fade in and out
    const [urlInput, setUrlInput] = useState("");

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFade(false); // Fade out the current placeholder

            setTimeout(() => {
                // After fade out, change the placeholder and fade it in
                setIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
                setCurrentPlaceholder(placeholders[index]);
                setFade(true);
            }, 200); // Half the interval time to allow for fade in and fade out
        }, 1555);

        return () => clearInterval(intervalId);
    }, [index]);

    const placeholdersInput = [t("Enter your prompt here..."),
        t("Shift + Enter to add a new line")];
    const [currentPlaceholderInput, setCurrentPlaceholderInput] = useState(placeholdersInput[0]);
    const [indexInput, setIndexInput] = useState(0);
    const [fadeInput, setFadeInput] = useState(true); // State to manage fade in and out

    useEffect(() => {
        const intervalIdInput = setInterval(() => {
            setFadeInput(false); // Fade out the current placeholder

            setTimeout(() => {
                // After fade out, change the placeholder and fade it in
                setIndexInput((prevIndex) => (prevIndex + 1) % placeholdersInput.length);
                setCurrentPlaceholderInput(placeholdersInput[indexInput]);
                setFadeInput(true);
            }, 750); // Half the interval time to allow for fade in and fade out
        }, 1500);

        return () => clearInterval(intervalIdInput);
    }, [indexInput]);

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" p={0} boxShadow="sm" borderRadius="md"
             border="0px" borderColor="gray.300" h="100%" maxW="100%">
            <Box h={'90.5%'} overflowY="auto" bg={'white'} className={currentMessages.length > 7 ? "delayed-survey" : "normal"}>
                <Flex justifyContent={'flex-start'} flexDirection={'column'} height={'100%'}>
                    {currentMessages.length > 0 ?
                        currentMessages.slice(-40).map((message, index, slicedArray) => {
                                const isLastMessage = index === slicedArray.length - 1;
                                return (
                                    <MessageBubble
                                        sessionToken={sessionToken}
                                        socketID={socketID}
                                        message={message}
                                        isLastMessage={isLastMessage}
                                        userBgColor={userBgColor}
                                        claudeBgColor={claudeBgColor}
                                        claudeColor={claudeColor}
                                        lastMessageRef={lastMessageRef}
                                    />
                                );
                            }) :
                        <Flex justifyContent={'flex-start'} flexDirection={'column'} height={"100vh"} alignItems={"center"} bg={'white'}>
                            <VStack spacing={6} align="center" bg={'white'} width={'100%'} mt={'1.7rem'}>



                                {/*{auth.isLoggedIn ?*/}
                                {/*    <VStack align="center" width={'83%'} pt={4} pb={4}*/}
                                {/*            borderRadius={'10px'}>*/}
                                {/*        <Box position="relative" display="inline-block" mt={0}>*/}
                                {/*            <Button*/}
                                {/*                alignSelf={"center"}*/}
                                {/*                borderRadius={'15px'}*/}
                                {/*                px={6}*/}
                                {/*                py={'1.5rem'}*/}
                                {/*                colorScheme={'orange'}*/}
                                {/*                bg={'orange.400'}*/}
                                {/*                _hover={{ bg: 'orange.500' }}*/}
                                {/*                onClick={() => {*/}
                                {/*                    navigate('/generation');*/}
                                {/*                    const userID = auth.isLoggedIn ? localStorage.getItem("userID") : "11111111-1111-1111-1111-111111111111"*/}
                                {/*                    initializeGeneration("Untitled Document", userID).then(r => {*/}
                                {/*                        navigate(`/generation/${r.id}`)*/}
                                {/*                        window.location.reload();*/}
                                {/*                        console.log("initialize generation here", r)*/}
                                {/*                    });*/}
                                {/*                }}>*/}
                                {/*                <Box*/}
                                {/*                    display="flex"*/}
                                {/*                    alignItems="center"*/}
                                {/*                    fontSize={14}*/}
                                {/*                    justifyContent="start"*/}
                                {/*                    color="white"*/}
                                {/*                    pointerEvents="none" // Allows click through to the input*/}
                                {/*                    opacity={fade ? 1 : 0} // Fade in and out*/}
                                {/*                    transition="opacity 0.75s ease-in-out"*/}
                                {/*                    whiteSpace="nowrap" // Prevents wrapping*/}
                                {/*                    overflow="hidden" // Hides overflow content*/}
                                {/*                    textOverflow="ellipsis" // Adds ellipsis to overflow content*/}
                                {/*                >*/}
                                {/*                    {currentPlaceholder}*/}
                                {/*                </Box>*/}
                                {/*            </Button>*/}
                                {/*            <Badge*/}
                                {/*                position="absolute"*/}
                                {/*                colorScheme="purple"*/}
                                {/*                variant="solid"*/}
                                {/*                top="0"*/}
                                {/*                right="-0.5"*/}
                                {/*                fontSize="0.7em"*/}
                                {/*                borderRadius="5"*/}
                                {/*                px="2"*/}
                                {/*                py="1"*/}
                                {/*                transform="translate(50%, -50%)"*/}
                                {/*                boxShadow="0px 1px 6px -1px rgba(0, 0, 0, 0.1), 0px 1px 6px -1px rgba(0, 0, 0, 0.1)"*/}
                                {/*                zIndex="1"*/}
                                {/*            >*/}
                                {/*                New*/}
                                {/*            </Badge>*/}
                                {/*        </Box>*/}
                                {/*    </VStack> : null}*/}





                                <VStack spacing={6}>
                                    <HStack spacing={6}>
                                        <Text fontSize="xl" fontWeight="bold">
                                            {t("Examples")}
                                        </Text>
                                    </HStack>
                                    <VStack spacing={3} align={'center'}>
                                        {[t("Give a detailed summary of the entire document"),
                                            t("Create a detailed notes outline"),
                                            t("Explain the experimental methods used in this study?")].map((example, index) => (
                                            <Button justifyContent="flex-start" variant="ghost" key={index}
                                                    width={['70%','75%', '90%', '95%', '111%']} py={'1.5rem'}
                                                    borderWidth={1} borderColor={lightTheme.colors.gray}
                                                    _hover={{ bg: lightTheme.colors.lightGray }} borderRadius={'10px'}
                                                    onClick={() => {
                                                        dispatch(setCurrentChatInput(example));
                                                        textareaRef.current.focus();
                                                    }}>
                                                <Flex justifyContent="space-between" width="100%" alignItems="center">
                                                    <Text fontSize={"14px"} fontWeight={'600'} color={'blue'} mr={2}
                                                          noOfLines={[2, 3, 4]} style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}>
                                                        "{example}"
                                                    </Text>
                                                    <Icon as={AiOutlineArrowRight} color={'blue'} fontWeight={'bold'}/>
                                                </Flex>
                                            </Button>
                                        ))}
                                    </VStack>
                                </VStack>







                                <VStack spacing={6}>
                                    <HStack spacing={6}>
                                        <Text fontSize="xl" fontWeight="bold">{t("Tips")}</Text>
                                    </HStack>
                                    <VStack align="center" width={'83%'} spacing={3} borderWidth={1} borderColor={lightTheme.colors.gray}
                                            borderRadius={'10px'} p={'1.5rem'}>
                                        <List spacing={5} textAlign="start">
                                            <ListItem key={1}>
                                                <Flex align="start">
                                                    <ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={4} />
                                                    <Text fontSize={"14px"} fontWeight={'500'}>{t("Provide clear, detailed instructions for best results.")}</Text>
                                                </Flex>
                                            </ListItem>
                                            <ListItem key={2}>
                                                <Flex align="start">
                                                    <ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={4} />
                                                    <Text fontSize={"14px"} fontWeight={'500'}>{t("Provide enough context in your question so the AI knows what you want.")}</Text>
                                                </Flex>
                                            </ListItem>
                                            <ListItem key={2}>
                                                <Flex align="start">
                                                    <ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={4} />
                                                    <Text fontSize={"14px"} fontWeight={'500'}>{t("Say \"respond in high school language\" for easier understanding.")}</Text>
                                                </Flex>
                                            </ListItem>
                                        </List>
                                    </VStack>
                                </VStack>








                            </VStack>
                        </Flex>

                    }
                </Flex>
            </Box>
            {isAskVisible ?
                <Box bg={'white'} alignItems={'flex-start'} display={'flex'}
                     flexDirection={'row'} width={'100%'} justifyContent={'space-between'}>
                    <Box borderWidth={'1px'} borderColor={lightTheme.colors.darkGray} borderRadius={'0.5rem'} borderBottomWidth={'0px'}
                         padding={'1.2rem'} borderBottomRadius={'0rem'} borderRightWidth={'0rem'} borderTopRightRadius={'0rem'}
                         width={'95%'} maxHeight={'26rem'}
                         overflowY={'auto'}>
                        <Text>{askContent.content.text}</Text>
                    </Box>
                    <button
                        style={{
                            background: lightTheme.colors.lightGray,
                            border: "1px",
                            borderRadius:'0px',
                            padding:'0.1rem',
                            cursor: "pointer",
                            outline: "none"
                        }}
                        onClick={() => {
                            dispatch(toggleIsAskVisibleFalse())
                            dispatch(setAskContent(null))
                        }}
                    >
                        <RiCloseFill size={'1.7rem'}/>
                    </button>
                </Box> : null}
            {isAskImageVisible ?
                <Box bg={'white'} alignItems={'flex-start'} display={'flex'}
                     flexDirection={'row'} width={'100%'} justifyContent={'space-between'}>
                    <Box borderWidth={'1px'} borderColor={lightTheme.colors.darkGray} borderRadius={'0.5rem'} borderBottomWidth={'0px'}
                         padding={'1.2rem'} borderBottomRadius={'0rem'} borderRightWidth={'0rem'} borderTopRightRadius={'0rem'}
                         width={'95%'} maxHeight={'26rem'}
                         overflowY={'auto'}>
                        <Image src={askImageContent.content.image} alt="Asking about this Image" maxW="100%" maxH="100%"/>
                    </Box>
                    <button
                        style={{
                            background: lightTheme.colors.lightGray,
                            border: "1px",
                            borderRadius:'0px',
                            padding:'0.1rem',
                            cursor: "pointer",
                            outline: "none"
                        }}
                        onClick={() => {
                            dispatch(toggleIsAskImageVisibleFalse())
                            dispatch(setAskImageContent(null))
                        }}
                    >
                        <RiCloseFill size={'1.7rem'}/>
                    </button>
                </Box> : null}
            {isFollowUpVisible ?
                <Box bg={'white'} alignItems={'flex-start'} display={'flex'}
                     flexDirection={'row'} width={'100%'} justifyContent={'space-between'}>
                    <Box borderWidth={'1px'} borderColor={lightTheme.colors.darkGray} borderRadius={'0.5rem'} borderBottomWidth={'0px'}
                         padding={'1.2rem'} borderBottomRadius={'0rem'} borderRightWidth={'0rem'} borderTopRightRadius={'0rem'}
                         width={'95%'} paddingLeft={'1.75rem'}
                         maxHeight={'26rem'}
                         overflowY={'auto'}>
                        <Markdown remarkPlugins={[remarkGfm]}
                              components={markdownComponents}
                              children={followUpContent.response} />
                    </Box>
                    <button
                        style={{
                            background: lightTheme.colors.lightGray,
                            border: "1px",
                            borderRadius:'0px',
                            padding:'0.1rem',
                            cursor: "pointer",
                            outline: "none"
                        }}
                        onClick={() => {
                            dispatch(toggleIsFollowUpVisibleFalse())
                            dispatch(setFollowUpContent(null))
                        }}
                    >
                        <RiCloseFill size={'1.7rem'}/>
                    </button>
                </Box> : null}
            <form onSubmit={modifiedHandleNewMessage} style={{ backgroundColor: 'white' }}>
                <VStack align="center" h="auto" marginX={'0.5rem'} marginBottom={'0.4rem'} borderWidth={'1px'}
                        borderColor={isInputFocused ? 'blue' : lightTheme.colors.darkGray}
                        borderRadius={'md'} >
                    <HStack width={'100%'} position={'relative'} spacing={0} align={"flex-end"} justifyContent={'space-between'}>
                        <Textarea
                            name="message-input"
                            ref={textareaRef}
                            placeholder={
                            isAskImageVisible ? t("Ask a question about this image...") :
                                isAskVisible
                                    ? t("Ask your question...")
                                    : isFollowUpVisible
                                        ? t("Follow up...")
                                        : currentPlaceholderInput
                            }
                            onKeyDown={handleKeyDown}
                            value={currentChatInput}
                            style={{ border: 'none', borderRight:'none', borderRightColor:"white", marginRight: 0,
                                borderTopWidth: isAskVisible || isFollowUpVisible || isAskImageVisible ? '0px' : '1px',
                                borderTopLeftRadius: isAskVisible || isFollowUpVisible || isAskImageVisible ? '0px' : '10px',
                                borderTopRightRadius: isAskVisible || isFollowUpVisible || isAskImageVisible ? '0px' : '10px'}}
                            _focus={{ boxShadow: 'none', outline: 'none' }}
                            onChange={(e) => dispatch(setCurrentChatInput(e.target.value))}
                            minH={'3.5rem'}
                            onFocus={() => setIsInputFocused(true)}
                            onBlur={() => setIsInputFocused(false)}
                            overflowY="auto"  // Allow vertical scrollbar
                            maxH="29rem"
                            resize="none"  // Disable manual resizing
                            onInput={(e) => {  // Auto-expand as text overflows
                                e.currentTarget.style.height = 'auto';
                                e.currentTarget.style.height = (e.currentTarget.scrollHeight) + 'px';
                            }}
                            required
                            width={'92%'}
                        />
                        <Box width={'2.85rem'}>
                            {isTyping ?
                                <Button
                                    aria-label="Stop generation"
                                    type="submit"
                                    position="relative"
                                    cursor="pointer"
                                    _hover={{ backgroundColor: 'white' }}
                                    borderRadius="md"
                                    backgroundColor={"white"}
                                    transition="background 0.2s"
                                    height={'2.5rem'}
                                    width={'100%'}
                                    size={'32'}
                                    onClick={() => {
                                        stopClaudeResponse();
                                    }}
                                >
                                    <BiStopCircle style={{ fontSize: '22px' }}/>
                                </Button> :
                                <Button
                                    aria-label="Send message"
                                    type="submit"
                                    isDisabled={!currentChatInput || (isTyping || isThinking)}
                                    position="relative"
                                    cursor="pointer"
                                    _hover={{ backgroundColor: 'white' }}
                                    borderRadius="md"
                                    backgroundColor={"white"}
                                    transition="background 0.2s"
                                    height={'2.5rem'}
                                    width={'100%'}
                                    size={'32'}
                                    onClick={() => {
                                        modifiedHandleNewMessage();
                                    }}
                                >
                                    <AiOutlineSend style={{ fontSize: '22px' }}/>
                                </Button>}
                        </Box>
                    </HStack>

                </VStack>
            </form>
            {currentMessages.length === 0 ?
                <Box justifyContent={"flex-end"} display={"flex"} mr={'0.6rem'}>
                    <Text fontSize={"xs"} color={"gray"}>{t("Shift + Enter to add a new line")}</Text>
                </Box>
                : null}
            <SignUpScreen1 isOpenSignUpScreen1={isOpenSignUpScreen1} onCloseSignUpScreen1={onCloseSignUpScreen1} onOpenSignInScreen1={onOpenSignInScreen1}/>
            <SignInScreen1 isOpenSignInScreen1={isOpenSignInScreen1} onCloseSignInScreen1={onCloseSignInScreen1} onOpenSignUpScreen1={onOpenSignUpScreen1}/>
            <PricingScreen isOpenPricingScreen={isOpenPricingScreen}
                           onClosePricingScreen={onClosePricingScreen}
                           onOpenPricingScreen={onOpenPricingScreen}/>
        </Box>
    );
};
