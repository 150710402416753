import React, { useState } from "react";
import {Box, Button, HStack, useToast, VStack} from "@chakra-ui/react";
import {useDispatch} from "react-redux";
import {
    setAskContent, setAskImageContent, setFollowUpContent, toggleIsAskImageVisibleTrue,
    toggleIsAskVisible,
    toggleIsAskVisibleTrue, toggleIsFollowUpVisibleFalse,
    toggleIsFollowUpVisibleTrue
} from "../../redux/reducers";
import {useAuth} from "../../redux/AuthProvider";
import {useTranslation} from "react-i18next";

const CustomTipImage = ({ toggleAreaSelection, onConfirmHighlight, hideTipAndSelection,
                       content, position }) => {
    const [noteText, setNoteText] = useState('');
    const [showNoteBox, setShowNoteBox] = useState(false);
    const dispatch = useDispatch();
    const auth = useAuth();
    const { t } = useTranslation();

    const toggleNoteBox = () => {
        setShowNoteBox(!showNoteBox);
    };

    const toggleQuestionBox = () => {
        console.log("content: ", content)
        console.log("position: ", position)
        hideTipAndSelection();
        toggleAreaSelection();
        dispatch(toggleIsAskImageVisibleTrue());
        dispatch(setAskImageContent({content: content, position: position}))
        dispatch(toggleIsFollowUpVisibleFalse());
        dispatch(setFollowUpContent(null));
    }

    const handleNoteTextChange = (e) => {
        setNoteText(e.target.value);
    };

    const handleConfirmHighlight = (forReal) => {
        onConfirmHighlight({ text: noteText }, forReal);
        toggleNoteBox();
    };

    const buttonStyles = {
        backgroundColor: "black",
        fontSize: 'xs',
        color: "white",
        _hover: { backgroundColor: "gray.600" },
        borderRadius: '10px',
    };

    const textAreaStyles = {
        width: '13.1rem',
        height: 'auto',
        maxHeight: '250px',
        minHeight: '4.5rem',
        wrap: 'soft',
        borderRadius: '10px',
        padding: '8px',
        fontSize: '0.9rem',
        overflowY:"auto",  // No vertical scrollbar
        resize:"none",  // Disable manual resizing
        _focus: { boxShadow: 'none', outline: 'none' },
    };

    return (
        <HStack bg={'black'} borderRadius={'10px'}>
            {!showNoteBox && (
                <Button onClick={() => {
                    toggleAreaSelection();
                    handleConfirmHighlight(false);
                }} {...buttonStyles}>
                    {t('Add Note')}
                </Button>
            )}

            {!showNoteBox && (
                <Button onClick={toggleQuestionBox} {...buttonStyles}>
                    {t('Ask about this Image')}
                </Button>
            )}

            {showNoteBox && (
                <VStack backgroundColor="black" borderRadius={'10px'} padding={'0.5rem'} spacing={2} align={"flex-end"}>
                    <textarea
                        placeholder={t('Add your note here...')}
                        value={noteText}
                        autoFocus={true}
                        onChange={handleNoteTextChange}
                        style={textAreaStyles}
                        onInput={(e) => {  // Auto-expand as text overflows
                            e.currentTarget.style.height = 'auto';
                            e.currentTarget.style.height = (e.currentTarget.scrollHeight) + 'px';
                        }}
                        onFocus={(e) => {
                            e.currentTarget.style.outline = 'none';
                        }}
                    />
                    <Button size="xs" colorScheme="blue" onClick={() => {
                        handleConfirmHighlight(true);
                    }}>
                        {t('Save')}
                    </Button>
                </VStack>
            )}
        </HStack>
    );
};

export default CustomTipImage;
