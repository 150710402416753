import React from "react";

const HighlightPopup = ({ comment }) =>
    comment.text ? (
        <div className="Highlight__popup">
            {comment.text}
        </div>
    ) : null;

export default HighlightPopup;
