import {Button} from "@chakra-ui/react";
import lightTheme from "../../utils/styles";

export const CustomIconButton = ({ icon: Icon, size, onClick, ariaLabel }) => (
    <Button
        aria-label={ariaLabel}
        cursor="pointer"
        borderRadius="md"
        p={0}
        minW="2rem"
        minH="2rem"
        maxW="2rem"
        maxH="2rem"
        transition="background 0.2s"
        _hover={{
            backgroundColor: lightTheme.colors.darkGray,
        }}
        onClick={onClick}
    >
        <Icon size={size} />
    </Button>
);

export const CustomIconButtonWhite = ({ icon: Icon, size, onClick, ariaLabel }) => (
    <Button
        aria-label={ariaLabel}
        cursor="pointer"
        borderRadius="md"
        backgroundColor={lightTheme.colors.white}
        p={0}
        minW="2rem"
        minH="2rem"
        maxW="2rem"
        maxH="2rem"
        transition="background 0.2s"
        _hover={{
            backgroundColor: lightTheme.colors.gray,
        }}
        onClick={onClick}
    >
        <Icon size={size} />
    </Button>
);
