import React from 'react';
import {
    Box,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    Input,
    Text,
    Tooltip,
    Grid,
    Button, HStack, Badge
} from '@chakra-ui/react';
import { FaBars, FaChevronUp, FaChevronDown, FaExpand } from 'react-icons/fa';
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import {FiChevronDown, FiChevronUp, FiMinimize, FiZoomIn, FiZoomOut} from 'react-icons/fi';
import lightTheme from "../../utils/styles";
import {CustomIconButton} from "../../components/buttons/CustomIconButton";
import {SiPowerpages} from "react-icons/si";
import {useTranslation} from "react-i18next";

const CustomMenuButton = React.forwardRef((props, ref) => {
    const { size, ...rest } = props;

    return (
        <Box
            as={SiPowerpages}
            size={size}
            cursor="pointer"
            borderRadius="md"
            p={2}
            transition="background 0.2s"
            _hover={{
                backgroundColor: lightTheme.colors.darkGray,
            }}
            ref={ref}
            {...rest}
        />
    );
});

const PDFPageHeader = ({
                           thumbnails, currentPage, totalPages, zoomLevel, areaSelectionEnabled,
                           jumpToPage, onPageChanged, toggleAreaSelection, toggleIsFullScreen, isFullScreen,
                           toggleZoomLevelOut, toggleZoomLevelIn
                       }) => {
    const {t} = useTranslation();
    return (
        <Box backgroundColor={lightTheme.colors.lightGray} width={"100%"} height={"3rem"} overflow={"auto"}
             justifyContent={'space-between'} alignItems={'center'} display={'flex'} px={'1rem'}>
            <HStack spacing={2}>
                <Menu>
                    <MenuButton as={CustomMenuButton} size={31}/>
                    <MenuList zIndex={999}>
                        <Box maxHeight="38rem" overflowY="auto">
                            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                {
                                    thumbnails.length !== 0 ?
                                        thumbnails.map((thumbnail, index) => (
                                            <MenuItem onClick={() => jumpToPage(index + 1)}>
                                                <Box display={'flex'} flexDirection={'column'}
                                                     justifyContent={'center'} alignItems={'center'}>
                                                    <img src={thumbnail} alt={`Page ${index + 1}`} />
                                                    <p fontSize={'0.5rem'}>{index + 1}</p>
                                                </Box>
                                            </MenuItem>
                                        )) : null}
                            </Grid>
                        </Box>
                    </MenuList>
                </Menu>
                <CustomIconButton
                    icon={FiChevronUp}
                    size={25}
                    ariaLabel="Arrow Up"
                    onClick={() => {
                        if (currentPage > 1) {
                            jumpToPage(currentPage - 1);
                        }
                    }}
                />
                <Input
                    placeholder={t("Go to page")}
                    backgroundColor={lightTheme.colors.white}
                    width={"4.75rem"}
                    type="number"
                    min={1}
                    max={totalPages}
                    value={currentPage}
                    onChange={(e) => {
                        onPageChanged({currentPage: e.target.value,
                            totalPages: totalPages})
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            jumpToPage(currentPage);
                        }
                    }}
                />
                <CustomIconButton
                    icon={FiChevronDown}
                    size={25}
                    ariaLabel="Arrow Down"
                    onClick={() => {
                        if (currentPage < totalPages) {
                            jumpToPage(currentPage + 1);
                        }
                    }}
                />
            </HStack>









            <Tooltip hasArrow label={t("Click and drag your cursor to select an image. Ask a question about it!")} bg='black' color="white">
                <Box position="relative" display="inline-block">
                    <button onClick={toggleAreaSelection} style={{
                        backgroundColor: areaSelectionEnabled ? 'blue' : lightTheme.colors.lightGray,
                        color: areaSelectionEnabled ? 'white' : 'black',
                        padding: '10px',
                        _hover: {backgroundColor: lightTheme.colors.darkGray},
                        borderColor: 'lightgray',
                        borderWidth: areaSelectionEnabled ? '0px' : '0.5px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        position: "relative",
                        height: '80%',
                        fontSize: 'small',
                    }}>
                        {t("Image Select")}
                    </button>
                    {/*<Badge*/}
                    {/*    position="absolute"*/}
                    {/*    colorScheme="purple"*/}
                    {/*    variant="solid"*/}
                    {/*    top="5"*/}
                    {/*    right="-8"*/}
                    {/*    fontSize="0.7em"*/}
                    {/*    borderRadius="5"*/}
                    {/*    px="2"*/}
                    {/*    py="1"*/}
                    {/*    transform="translate(50%, -50%)"*/}
                    {/*    boxShadow="0px 1px 6px -1px rgba(0, 0, 0, 0.1), 0px 1px 6px -1px rgba(0, 0, 0, 0.1)"*/}
                    {/*    zIndex="99"*/}
                    {/*>*/}
                    {/*    {t("New")}*/}
                    {/*</Badge>*/}
                </Box>
            </Tooltip>









            <HStack spacing={2}>
                <CustomIconButton
                    icon={FiZoomOut}
                    size={18}
                    ariaLabel="Zoom Out"
                    onClick={() => {
                        toggleZoomLevelOut();
                    }}
                />
                <Text>
                    {Math.round(zoomLevel * 100)}%
                </Text>
                <CustomIconButton
                    icon={FiZoomIn}
                    size={18}
                    ariaLabel="Zoom In"
                    onClick={() => {
                        toggleZoomLevelIn();
                    }}
                />
                {isFullScreen ?
                    <CustomIconButton
                        icon={FiMinimize}
                        size={20}
                        ariaLabel="Minimize Screen"
                        onClick={() => {
                            toggleIsFullScreen();
                        }}
                    /> :
                    <CustomIconButton
                        icon={FaExpand}
                        size={20}
                        ariaLabel="Maximize Screen"
                        onClick={() => {
                            toggleIsFullScreen();
                        }}
                    />}
            </HStack>






        </Box>
    );
};

export default PDFPageHeader;
