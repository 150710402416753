import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    List,
    ListItem,
    Text,
    Image,
    VStack,
    HStack,
    Button,
    useColorModeValue,
    useToast,
    Tooltip,
} from "@chakra-ui/react";
import lightTheme from "../../utils/styles";
import VerticalMenu from "../Reuseable/VerticalMenu";
import {useAuth} from "../../redux/AuthProvider";
import {FaCheck} from "react-icons/fa";
import {useTranslation} from "react-i18next";

export const Highlights = ({ highlights, deleteHighlight, updateHighlightInDoc }) => {
    const updateHash = (highlight) => {
        document.location.hash = `highlight-${highlight.id}`;
    };

    const textColor = useColorModeValue("gray.800", "gray.100");
    const [numOfLinesComment, setNumOfLinesComment] = React.useState(4);
    const [numOfLinesText, setNumOfLinesText] = React.useState(4);
    const toast = useToast();
    const auth = useAuth();
    const [editableNoteId, setEditableNoteId] = useState(null);
    const [editedNotes, setEditedNotes] = useState({});
    const inputRef = useRef(null);
    const [hoveredHighlightId, setHoveredHighlightId] = useState(null);
    const {t} = useTranslation();

    useEffect(() => {
        function handleClickOutside(event) {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setEditableNoteId(null);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleNoteUpdate = async (originalHighlight, newText, noteId) => {
        updateHighlightInDoc(originalHighlight.content, originalHighlight.position, {
            text: newText,
        }, noteId)
        setEditableNoteId(null); // Disable edit mode after update
        toast({
            title: t("Edits saved."),
            status: "success",
            duration: 3000,
            isClosable: true,
        })
    };

    return (
        highlights.length > 0 ?
                <Box bg={lightTheme.colors.white} p={0} borderRadius="md" width="100%" height="100%"
                     overflowY="auto" overflowX="hidden">
                    <List spacing={0} >
                        {highlights.map((highlight, index) => (
                            <ListItem
                                key={index}
                                p={4}
                                bg="white"
                                borderRadius={0}
                                borderTopWidth={1.2}
                                borderBottomWidth={1.2}
                                borderColor={lightTheme.colors.lightGray}
                                _hover={{ bg: lightTheme.colors.lightGray, cursor: "pointer" }}
                                onMouseEnter={() => setHoveredHighlightId(highlight.id)} // Track hover
                                onMouseLeave={() => setHoveredHighlightId(null)} // Track hover
                                onClick={() => {
                                    console.log("Clicked on highlight");
                                    updateHash(highlight);
                                }}
                            >
                                <VStack align="start" spacing={'0.8rem'}>
                                    <HStack justify={'space-between'} width={'100%'}>
                                        <Box width={"90%"}>
                                            {editableNoteId === highlight.id ? (
                                                <textarea
                                                    ref={inputRef}
                                                    value={editedNotes[highlight.id] !== undefined ? editedNotes[highlight.id] : highlight.comment.text}
                                                    onChange={(e) => setEditedNotes({ ...editedNotes, [highlight.id]: e.target.value })}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter' && !e.shiftKey) {
                                                            handleNoteUpdate(highlight, editedNotes[highlight.id], highlight.id);
                                                        }
                                                    }}
                                                    onFocus={(e) => {
                                                        e.target.style.height = 'auto'; // Reset height to recalculate
                                                        e.target.style.height = Math.min(e.target.scrollHeight, 13) + 'rem';
                                                    }}
                                                    onInput={(e) => {
                                                        e.target.style.height = 'auto';
                                                        e.target.style.height = e.target.scrollHeight + 'px';
                                                    }}
                                                    style={{
                                                        fontSize: 'medium',
                                                        width: '100%',
                                                        cursor: 'text',
                                                        border: '1px solid gray',
                                                        padding: '0.25rem',
                                                        borderRadius: '0.25rem',
                                                        overflowY: 'auto',
                                                        resize: 'none',
                                                        maxHeight: '13rem',
                                                    }}
                                                    autoFocus={true}
                                                />
                                            ) : (
                                                <Text fontWeight="bold" color={textColor} width="100%"
                                                      noOfLines={(hoveredHighlightId === highlight.id) ? undefined : numOfLinesComment}>
                                                    {highlight.comment.text}
                                                </Text>
                                            )}

                                        </Box>
                                        {editableNoteId === highlight.id ? ( // Check if in edit mode
                                            <Tooltip label={t("Save")}>
                                                <Button
                                                    onMouseDown={(e) => {
                                                        e.preventDefault(); // Prevent default behavior (text selection)
                                                        handleNoteUpdate(highlight, editedNotes[highlight.id], highlight.id);
                                                    }}
                                                    colorScheme="green"
                                                    variant="unstyled" // Remove the default button styles
                                                >
                                                    <FaCheck size={16} color="green" />
                                                </Button>
                                            </Tooltip>
                                        ) : (
                                            <VerticalMenu
                                                menuOptions={[
                                                    {
                                                        label: t('Edit note'),
                                                        onClick: () => {
                                                            setEditableNoteId(highlight.id);
                                                            setEditedNotes({ ...editedNotes, [highlight.id]: highlight.comment.text });
                                                        },
                                                    },
                                                    {
                                                        label: t('Delete note'),
                                                        onClick: () => {
                                                            deleteHighlight(highlight, highlight.id)
                                                            toast({
                                                                title: t("Note deleted."),
                                                                status: "success",
                                                                duration: 3000,
                                                                isClosable: true,
                                                            })
                                                        },
                                                    },
                                                ]}
                                            />
                                        )}
                                    </HStack>

                                    {highlight.content.text && (
                                        <Text
                                            as="blockquote"
                                            mt={0}
                                            color={textColor}
                                            width="100%"
                                            noOfLines={numOfLinesText}
                                            _hover={{ noOfLines: numOfLinesText }}
                                        >
                                            {highlight.content.text}
                                        </Text>
                                    )}

                                    {highlight.content.image && (
                                        <Box mt={0} width="100%">
                                            <Image src={highlight.content.image} alt="Screenshot" borderRadius="md" maxW="100%" />
                                        </Box>
                                    )}

                                    <Text color={textColor} fontWeight={"bold"} fontSize={'sm'}>
                                        {t("Page")} {highlight.position.pageNumber}
                                    </Text>
                                </VStack>
                            </ListItem>
                        ))}
                    </List>
                </Box> :
                <Box bg={lightTheme.colors.white} p={0} borderRadius="md" width="100%" height="100%"
                     overflowY="auto" overflowX="hidden" justifyContent={"center"} alignItems={"center"} display={'flex'}>
                    {auth.isLoggedIn ?
                        <Text>{t("Highlight stuff in your document. Added notes will appear here!")}</Text> :
                        <Text>{t("To add notes in your document, please sign up first!")}</Text>}
                </Box>
    );
};
