import {useDispatch, useSelector} from "react-redux";
import {Box, Button, Icon} from "@chakra-ui/react";
import {
    setAskContent,
    setFollowUpContent,
    toggleIsAskVisibleFalse,
    toggleIsFollowUpVisibleTrue
} from "../../redux/reducers";
import {FiCornerUpLeft} from "react-icons/fi";
import React from "react";
import {useTranslation} from "react-i18next";

const FollowUpButton = ({sessionToken, socketID, claudeMessage}) => {
    const currentChatInput = useSelector(state => state.settings.currentChatInput);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    return (
        <Button
            bg="white"
            color="black"
            borderRadius={"0.5rem"}
            marginTop={'1rem'}
            borderWidth={'0.5px'}
            borderColor={'lightgray'}
            width={'6rem'}
            _hover={{
                transform: 'scale(1.05)'
            }}
            _active={{
                transform: 'scale(0.95)'
            }}
            _focus={{
                boxShadow: "0 0 1px 2px #a4d7e1, 0 1px 1px rgba(0, 0, 0, .15)"
            }}
            size="xs"
            p={'0.9rem'}
            rounded="md"
        >
            <Box as="span" display="flex" alignItems="center" justifyContent={"center"} onClick={() => {
                dispatch(toggleIsFollowUpVisibleTrue());
                dispatch(setFollowUpContent(claudeMessage));
                dispatch(toggleIsAskVisibleFalse());
                dispatch(setAskContent(null));
            }}>
                <Icon as={FiCornerUpLeft} w={3} h={3} />
                <Box as="span" ml={2}>
                    {t("Follow Up")}
                </Box>
            </Box>
        </Button>
    );
};

export default FollowUpButton;
