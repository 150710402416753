'use client'

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Flex,
    Text,
    Container, Heading,
} from '@chakra-ui/react'

import { ChevronDownIcon } from '@chakra-ui/icons'
import {useTranslation} from "react-i18next";

export default function FAQ() {
    const {t} = useTranslation()

    const faqData = [
        {
            question: t("What is the cancellation policy?"),
            answer: t("You can cancel your Pro subscription at any time. If you cancel, you will still have access to your Pro plan until the end of the current 30-day billing period. To cancel your subscription, click on Manage Subscription in the top right menu with your name."),
        },
        {
            question: t("Is there a free trial?"),
            answer: t("No. Because we offer the free plan where you can already try out the product for free, you will be billed once you enter your payment information and subscribe to the Pro Plan. We hope you do!"),
        },
        {
            question: t("Is Honeybear.ai available in other languages?"),
            answer: t("Yes, Honeybear.ai can understand and respond in over 95 languages. You can upload PDFs in your preferred language, and also ask questions in your preferred language."),
        },
        {
            question: t("Is my data secure and confidential?"),
            answer: t("We take privacy and security very seriously. All documents are fully encrypted and only you can access them. If you delete something, it is permanently deleted from our systems. In addition, all data is stored in cloud storage that is secure and GDPR-compliant."),
        },
        {
            question: t("What payment methods do you accept?"),
            answer: t("We use a secure payment processor, Stripe, to accept most major credit and debit cards."),
        },
        {
            question: t("Who do I contact if I have other issues or specific questions?"),
            answer: t("Please email: contact@honeybear.ai, and we will get back to you as soon as possible."),
        },
    ];

    return (
        <Flex
            align={'center'}
            justify={'center'}
            display={'flex'}
            width={'100%'}
            flexDirection={'column'}
            mb={'5rem'}
            bg={'white'}>
            <Heading as="h1" fontSize="4xl" mb={'3rem'} mt={'1rem'} fontWeight="600" textAlign={{ base: 'center', md: 'center' }}>
                {t("Frequently asked questions")}
            </Heading>
            <Container maxW="820px" width="100%">
                <Accordion allowMultiple bg="white" rounded="lg">
                    {faqData.map((faq, index) => (
                        <AccordionItem key={index}>
                            <AccordionButton
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                px={4}
                                py={5}
                                _hover={{ bg: 'gray.100' }}
                            >
                                <Text fontSize="md" color="black" fontWeight={'500'} textAlign={'left'}>
                                    {faq.question}
                                </Text>
                                <ChevronDownIcon fontSize="24px" />
                            </AccordionButton>
                            <AccordionPanel pt={2} pb={5}>
                                <Text color="gray.700">
                                    {faq.answer}
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            </Container>
        </Flex>
    )
}
