import React, {Component} from "react";
import {
    PdfLoader,
    PdfHighlighter,
    Highlight,
    Popup,
    AreaHighlight,
} from "@brandonyan5/react-pdf-highlighter";
import {MySpinner} from "../../components/Reuseable/MySpinner";
import { Sidebar } from "../../components/SidebarComponents/Sidebar";
import CustomTip from "../../components/CustomTips/CustomTip";
import "../../style/App.css";
import {Box, Flex, Grid, IconButton, Input, Menu, MenuButton, MenuItem, MenuList, Text} from "@chakra-ui/react";
import CustomTipImage from "../../components/CustomTips/CustomTipImage";
import HighlightPopup from "./HighlightPopup";
import {getClaudeResponse, postChatPair} from "../../utils/api";
import {
    addHighlightHelper, fetchPDFDocumentProxy,
    parseIdFromHash,
    resetHash,
    updateHighlightHelper, uploadHighlight, deleteHighlightHelper
} from "../../utils/pdf-page-helpers";
import { connect } from 'react-redux';
import {
    addMessageInDocument,
    addToCurrentChatResponse,
    setCurrentChatClaudeID,
    setCurrentChatQuestion,
    setCurrentChatSelectionText,
    setCurrentChatType,
    setCurrentChatUserID,
    setHighlightsInDocument,
    toggleIsChatVisible,
    toggleIsFullScreen,
    appendToMessageInDocument,
    updateMessageInDocument,
    resetCurrentChatResponse,
    setWhichTab,
    setIsPricingModalOpen,
    removeMessageInDocument
} from "../../redux/reducers";
import { v4 as uuidv4 } from 'uuid';
import FullScreenTemplate from "../../templates/FullScreenTemplate";
import PDFPageHeader from "./PDFPageHeader";
import lightTheme from "../../utils/styles";
import {FaArrowsAltH} from "react-icons/fa";
import CustomTipGeneration from "../../components/CustomTips/CustomTipGeneration";

class PDFPage extends Component {
    state = {
        url: this.props.document.url,
        highlights: this.props.document.highlights,
        areaSelectionEnabled: false,
        currentPage: 1,
        totalPages: 14,
        thumbnails: [],
        isRendering: false,
        renderQueue: [],
        zoomLevel: 1,
        isResizing: false,
        panelWidth: 60,  // initial width percentage of the left panel
    };

    handleMouseDown = (e) => {
        this.setState({
            isResizing: true,
            initialPos: e.clientX,
        });
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
    }

    handleMouseUp = () => {
        this.setState({
            isResizing: false,
        });
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
    }

    handleMouseMove = (e) => {
        if (this.state.isResizing) {
            const containerRect = this.container.getBoundingClientRect();
            const newPanelWidth = ((e.clientX - containerRect.left) / containerRect.width) * 100;

            // you can also add some conditions to set min and max width limits
            this.setState({ panelWidth: newPanelWidth });
        }
    }

    onPageChanged = ({ currentPage, totalPages }) => {
        //console.log(`onPageChanged: currentPage: ${currentPage}, totalPages: ${totalPages}`)
        this.setState({ currentPage: currentPage, totalPages: totalPages });
    };

    jumpToPage = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
        this.scrollToPageMaster(pageNumber)
        //console.log("jumping to page", pageNumber);
    };

    toggleAreaSelection = () => {
        console.log("ask_image_button_clicked");
        this.setState(prevState => ({
            areaSelectionEnabled: !prevState.areaSelectionEnabled
        }));
    };

    toggleZoomLevelOut = () => {
        this.setState(prevState => ({
            zoomLevel: prevState.zoomLevel / 1.1,
        }));
    }

    toggleZoomLevelIn = () => {
        this.setState(prevState => ({
            zoomLevel: prevState.zoomLevel * 1.1,
        }));
    }

    scrollViewerTo = (highlight) => {};

    scrollToHighlightFromHash = () => {
        const highlight = this.getHighlightById(parseIdFromHash());
        if (highlight) {
            this.scrollViewerTo(highlight);
        }
    };

    scrollViewerToPage = (pageNumber) => {};

    scrollToPageMaster = (pageNumber) => {
        this.scrollViewerToPage(pageNumber);
    }

    componentDidMount() {
        window.addEventListener(
            "hashchange",
            this.scrollToHighlightFromHash,
            false
        );
    }

    getHighlightById(id) {
        return this.state.highlights.find((highlight) => highlight.id === id);
    }

    addHighlight(highlight, highlightID) {
        uploadHighlight(this.props.id, localStorage.getItem("userID"), [{
            id: highlightID,
            position: highlight.position,
            content: highlight.content,
            comment: highlight.comment,
        }]).then(r => {});

        const newHighlights = addHighlightHelper(this.state.highlights, highlight, highlightID);
        this.setState({
            highlights: newHighlights,
        });
        // {newHighlights: newHighlights, documentID: documentID}
        console.log("newHighlights", newHighlights)
        this.props.setHighlightsInDocument({newHighlights: newHighlights, documentID: this.props.id});
    }

    deleteHighlight(highlight, highlightID) {
        deleteHighlightHelper(this.props.id, localStorage.getItem("userID"), [highlightID.toString()]).then(r => {});
        const updatedHighlights = this.state.highlights.filter((h) => h.id !== highlightID);
        this.setState({
            highlights: updatedHighlights,
        });
        this.props.setHighlightsInDocument({newHighlights: updatedHighlights, documentID: this.props.id});
    }

    updateHighlightInDoc(content, position, comment, highlightID) {
        uploadHighlight(this.props.id, localStorage.getItem("userID"), [{
            id: highlightID,
            position: position,
            content: content,
            comment: comment,
        }]).then(r => {});

        const newHighlights = this.state.highlights.map((h) => {
            if (h.id === highlightID) {
                return {
                    id: highlightID,
                    position: position,
                    content: content,
                    comment: comment,
                };
            }
            return h;
        })
        this.setState({
            highlights: newHighlights,
        });
        // {newHighlights: newHighlights, documentID: documentID}
        console.log("newHighlights", newHighlights)
        this.props.setHighlightsInDocument({newHighlights: newHighlights, documentID: this.props.id});
    }

    updateHighlight(highlightId, position, content) {
        const updatedHighlights = updateHighlightHelper(this.state.highlights, highlightId, position, content);
        this.setState({
            highlights: updatedHighlights,
        });
        this.props.setHighlightsInDocument({newHighlights: updatedHighlights, documentID: this.props.id});
    }

    handleMessageCreation = (type, question, selectionText = null) => {
        const userID = uuidv4();
        const claudeID = uuidv4();
        this.props.socket.off('receive_message');
        const userMessage = {
            id: userID,
            type: type,
            user: 'User',
            selection_text: selectionText,
            question: question
        };
        const claudeMessage = {
            id: claudeID,
            type: type,
            user: 'Claude',
            selection_text: selectionText,
            question: question,
            response: ""
        };
        this.props.addMessageInDocument({ messageToAdd: userMessage, documentID: this.props.document.id });
        this.props.addMessageInDocument({ messageToAdd: claudeMessage, documentID: this.props.document.id });
        return { userID, claudeID };
    }

    handleClaudeResponse = async (sessionToken, pageNumber, selectionText, question, type, socketID, userID, claudeID,
                                  messageHistory) => {
        this.props.resetCurrentChatResponse();
        this.props.setCurrentChatUserID(userID);
        this.props.setCurrentChatClaudeID(claudeID);
        this.props.setCurrentChatSelectionText(selectionText);
        this.props.setCurrentChatQuestion(question);
        this.props.setCurrentChatType(type);
        this.props.setIsThinking(true);
        getClaudeResponse(sessionToken, pageNumber, selectionText, question, type,
            socketID, this.props.toast, messageHistory).then(responseText => {
                if (responseText === "Hey! It looks like you uploaded a scanned document. We now support these documents, " +
                    "so please follow the on-screen instructions displayed when you uploaded this document. Thanks!") {
                    setTimeout(() => {
                        this.props.setIsTyping(false);
                        this.props.socket.off('receive_message');
                        postChatPair([
                            { id: userID, type: type, user: 'User', selection_text: selectionText, question: question },
                            { id: claudeID, type: type, user: 'Claude', selection_text: selectionText, question: question, response: responseText }
                        ], localStorage.getItem("userID"), this.props.document.id, this.props.isLoggedIn);
                    }, 925);
                }
                else if (responseText === "free tier exceeded. Please subscribe to continue using the service") {
                    console.log("pricing_modal_shown")
                    this.props.socket.off('receive_message');
                    console.log("Claude response PAID PLAN:", responseText)
                    localStorage.setItem("isChatExceeded", "true");
                    this.props.setIsPricingModalOpen(true);
                    this.props.setIsTyping(false);
                    this.props.setIsThinking(false);
                    this.props.removeMessageInDocument({ messageID: userID, documentID: this.props.document.id });
                    this.props.removeMessageInDocument({ messageID: claudeID, documentID: this.props.document.id });
                }
                else {
                    if (this.props.isTyping) {
                        this.props.setIsTyping(false);
                        this.props.socket.off('receive_message');
                        if (responseText !== undefined) {
                            if (responseText.includes("<instructions>")) {
                                // Find the index of the substring "<instructions>"
                                let index = responseText.indexOf('<instructions>');
                                if (index !== -1) {
                                    // Create a new string that contains everything up to the found substring
                                    let clippedString = responseText.slice(0, index);
                                    // Now, clippedString contains the part of responseString before "<instructions>"

                                    this.props.updateMessageInDocument({ messageID: claudeID, type: type, user: 'Claude', selection_text: selectionText,
                                        question: question, response: clippedString, documentID: this.props.document.id });
                                    console.log("prompt leak clipped", clippedString)
                                    postChatPair([
                                        { id: userID, type: type, user: 'User', selection_text: selectionText, question: question },
                                        { id: claudeID, type: type, user: 'Claude', selection_text: selectionText, question: question,
                                            response: clippedString }
                                    ], localStorage.getItem("userID"), this.props.document.id, this.props.isLoggedIn);
                                }

                            } else {
                                this.props.updateMessageInDocument({ messageID: claudeID, type: type, user: 'Claude', selection_text: selectionText,
                                    question: question, response: responseText, documentID: this.props.document.id });
                                console.log("Claude response SESSION EXPIRED:", responseText) // "Claude response SESSION EXPIRED:" "undefined"
                                postChatPair([
                                    { id: userID, type: type, user: 'User', selection_text: selectionText, question: question },
                                    { id: claudeID, type: type, user: 'Claude', selection_text: selectionText, question: question, response: responseText }
                                ], localStorage.getItem("userID"), this.props.document.id, this.props.isLoggedIn);
                            }
                        }
                    } else {
                        console.log("got here")
                        console.log("Claude responseText:", responseText)
                        if (!responseText === undefined) {
                            this.props.updateMessageInDocument({ messageID: claudeID, type: type, user: 'Claude', selection_text: selectionText,
                                question: question, response: "", documentID: this.props.document.id });
                            postChatPair([
                                { id: userID, type: type, user: 'User', selection_text: selectionText, question: question },
                                { id: claudeID, type: type, user: 'Claude', selection_text: selectionText, question: question, response: "" }
                            ], localStorage.getItem("userID"), this.props.document.id, this.props.isLoggedIn);
                        }
                    }
                }

        }).catch(error => {
            this.props.socket.off('receive_message');
            console.error("Error getting Claude response:", error);
        });
        this.props.socket.on('receive_message', function (data) {
            // console.log("Received message from Claude:", data.message)
            this.props.setIsThinking(false);
            this.props.setIsTyping(true);
            this.props.addToCurrentChatResponse(data.message);
            this.props.appendToMessageInDocument({ messageID: claudeID, type: type, user: 'Claude', selection_text: selectionText,
                question: question, response: data.message, documentID: this.props.document.id });
        }.bind(this));
    }

    stopClaudeResponse = () => {
        this.props.setIsThinking(false);
        this.props.setIsTyping(false);
        this.props.socket.off('receive_message');
        postChatPair([
            {
                id: this.props.currentChatUserID, type: this.props.currentChatType, user: 'User',
                selection_text: this.props.currentChatSelectionText, question: this.props.currentChatQuestion
            },
            {
                id: this.props.currentChatClaudeID, type: this.props.currentChatType, user: 'Claude',
                selection_text: this.props.currentChatSelectionText, question: this.props.currentChatQuestion,
                response: this.props.currentChatResponse
            }
        ], localStorage.getItem("userID"),  this.props.document.id, this.props.isLoggedIn).then(r =>{
            this.props.resetCurrentChatResponse();
        });
    }

    getMessageHistory = () => {
        let message_history = []
        const message_history_length = this.props.document.messages.length;

        // If message_history_length is 0, leave message_history as an empty array
        if (message_history_length > 0) {
            // Determine how many messages to include in the history
            let messageLimit;
            if (message_history_length === 2) {
                messageLimit = 2; // Last 2 messages for message_history_length of 2
            } else if (message_history_length === 4) {
                messageLimit = 4; // Last 4 messages for message_history_length of 4
            } else if (message_history_length > 4) {
                messageLimit = 4; // Most recent 4 messages for message_history_length greater than 4
            } else {
                messageLimit = message_history_length; // This handles any case less than 2, though not explicitly required by the spec
            }

            // Iterate over the messages to populate message_history accordingly
            for (let i = message_history_length - 1; i >= message_history_length - messageLimit; i--) {
                const message = this.props.document.messages[i];
                if (message.user === 'User') {
                    if (message.type === "explain_selection") {
                        message_history.push({
                            user: "User",
                            type: 'explain_selection',
                            id: message.id,
                            selection_text: message.selection_text,
                            response: `
                            The user has asked you to explain the following selection:
                            ${message.selection_text}
                            `
                        });
                    }
                    else if (message.type === "summarize_selection") {
                        message_history.push({
                            user: "User",
                            type: 'summarize_selection',
                            id: message.id,
                            selection_text: message.selection_text,
                            response: `
                            The user has asked you to summarize the following selection:
                            ${message.selection_text}
                            `
                        });
                    }
                    else if (message.type === "ask_selection") {
                        message_history.push({
                            user: "User",
                            type: 'ask_selection',
                            id: message.id,
                            selection_text: message.selection_text,
                            response: `
                            The user has asked a question about the following selection:
        
                            SELECTION:
                            ${message.selection_text}
                            
                            QUESTION: 
                            ${message.question}
                            `
                        });
                    }
                    else if (message.type === "follow_up") {
                        console.log("follow_up_message", message);
                        message_history.push({
                            user: "User",
                            type: 'follow_up',
                            id: message.id,
                            selection_text: message.selection_text,
                            response: `
                            I'm going to ask you a follow-up question to a previous response you gave to one of my questions.
        
                            You provided this response previously:
                            ${message.selection_text}
                            
                            I am asking this follow-up question to the above response:
                            ${message.question}
                            
                            Please respond to my follow-up question.
                            `
                        });
                    }
                    else if (message.type === "ask_image") {
                        message_history.push({
                            user: "User",
                            question: message.question,
                            selection_text: message.selection_text,
                            type: 'ask_image',
                            id: message.id,
                        });
                    }
                    else {
                        message_history.push({
                            user: "User",
                            response: message.question,
                            type: 'general_question',
                            id: message.id,
                            selection_text: null,
                        });
                    }
                } else {
                    message_history.push({
                        user: "Claude",
                        response: message.response,
                        id: message.id,
                    });
                }
            }
        }
        return message_history;
    }

    handleChatResponseSelection = async (type, fullContent, sessionToken, socketID, question) => {
        const message_history = this.getMessageHistory();

        if (type === "ask_image") {
            const { userID, claudeID } = this.handleMessageCreation(type, question, fullContent.content.image);
            await this.handleClaudeResponse(sessionToken, fullContent.position.pageNumber - 1, fullContent.content.image, question,
                type, socketID, userID, claudeID, message_history);
        } else {
            const { userID, claudeID } = this.handleMessageCreation(type, question, fullContent.content.text);
            await this.handleClaudeResponse(sessionToken, fullContent.position.pageNumber - 1, fullContent.content.text, question,
                type, socketID, userID, claudeID, message_history);
        }
    }

    handleChatResponseNoSelection = async (type, pageNumber, sessionToken, socketID, question) => {
        const message_history = this.getMessageHistory();
        const { userID, claudeID } = this.handleMessageCreation(type, question);
        await this.handleClaudeResponse(sessionToken, pageNumber, null, question, type, socketID, userID, claudeID, message_history);
    }

    handleChatFollowUp = async (type, message_responding_to, follow_up_question, sessionToken, socketID) => {
        const { userID, claudeID } = this.handleMessageCreation(type, follow_up_question, message_responding_to);
        await this.handleClaudeResponse(sessionToken, null, message_responding_to, follow_up_question,
            type, socketID, userID, claudeID, []);
    }


    async createThumbnails(pdfDocument) {
        if (this.state.thumbnails.length > 0) {
            return;
        }
        const thumbnails = [];
        for (let i = 1; i <= pdfDocument.numPages; i++) {
            const page = await pdfDocument.getPage(i);
            const canvas = document.createElement("canvas");
            const viewport = page.getViewport({ scale: 0.2 });
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            try {
                await page.render({
                    canvasContext: canvas.getContext("2d"),
                    viewport,
                }).promise;
            } catch (e) {
                if (e.name === 'RenderingCancelledException') {
                    console.warn('Rendering was cancelled');
                } else {
                    console.error(e);
                }
            }
            thumbnails.push(canvas.toDataURL());
        }
        this.setState({ thumbnails });
        const theDocument = await fetchPDFDocumentProxy(this.props.document.url);
        this.setState({ totalPages: theDocument.numPages });
    }

    render() {
        return (
            <FullScreenTemplate>
                <div className="App" style={{ display: "flex", height: '100%',
                    width:'100%', }} ref={ref => this.container = ref}>
                    <div
                        style={{
                            height: "100%",
                            width: this.props.isChatVisible ? `${this.state.panelWidth}%` : '100%',
                            position: "relative",
                            backgroundColor: "yellow",
                        }}
                    >
                        <PDFPageHeader
                            thumbnails={this.state.thumbnails}
                            currentPage={this.state.currentPage}
                            totalPages={this.state.totalPages}
                            zoomLevel={this.state.zoomLevel}
                            areaSelectionEnabled={this.state.areaSelectionEnabled}
                            jumpToPage={this.jumpToPage}
                            onPageChanged={this.onPageChanged}
                            toggleAreaSelection={this.toggleAreaSelection}
                            toggleIsFullScreen={this.props.toggleIsFullScreen}
                            isFullScreen={this.props.isFullScreen}
                            toggleZoomLevelOut={this.toggleZoomLevelOut}
                            toggleZoomLevelIn={this.toggleZoomLevelIn}
                        />
                        <Box width={"100%"} height={'100%'} bg={lightTheme.colors.darkGray}
                             cursor={this.state.areaSelectionEnabled ? 'crosshair' : 'default'}>
                            <PdfLoader url={this.state.url} beforeLoad={
                                <div style={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                                    <MySpinner />
                                </div>
                            }>
                                {(pdfDocument) => {
                                    this.createThumbnails(pdfDocument).then(r => {});
                                    return (
                                    <PdfHighlighter
                                        zoomLevel={this.state.zoomLevel}
                                        pdfDocument={pdfDocument}
                                        enableAreaSelection={() => this.state.areaSelectionEnabled}
                                        onScrollChange={(currentPageNumber ) => {
                                            this.onPageChanged({ currentPage: currentPageNumber,
                                                totalPages: this.state.totalPages });
                                            resetHash();
                                        }}
                                        scrollRef={(scrollTo, scrollToPage) => {
                                            this.scrollViewerTo = scrollTo;
                                            this.scrollViewerToPage = scrollToPage;
                                            this.scrollToHighlightFromHash();
                                        }}
                                        onSelectionFinished={(
                                            position,
                                            content,
                                            hideTipAndSelection,
                                            transformSelection
                                        ) => {
                                            console.log("CONTENT", content); console.log("POSITION", position);
                                            const highlightID = uuidv4();
                                            if (content.text) {
                                                if (this.props.whichTab === 1 || this.props.whichTab === 3) {
                                                    return (
                                                        <CustomTip
                                                            onOpen={transformSelection}
                                                            hideTipAndSelection={hideTipAndSelection}
                                                            content={content}
                                                            position={position}
                                                            onConfirmHighlight={(comment, forReal) => {
                                                                if (forReal) {
                                                                    this.updateHighlightInDoc(content, position, comment, highlightID)
                                                                    hideTipAndSelection();
                                                                } else {
                                                                    this.addHighlight({ content, position, comment }, highlightID);
                                                                }
                                                            }}
                                                            onConfirmSummarize={async () => {
                                                                hideTipAndSelection();
                                                                await this.handleChatResponseSelection("summarize_selection", {content, position},
                                                                    this.props.sessionToken, this.props.socketID, null);
                                                            }}
                                                            onConfirmExplain={async () => {
                                                                hideTipAndSelection();
                                                                await this.handleChatResponseSelection("explain_selection", {content, position},
                                                                    this.props.sessionToken, this.props.socketID, null);
                                                            }}
                                                            onConfirmAsk={async (question) => {
                                                                hideTipAndSelection();
                                                                await this.handleChatResponseSelection("ask_selection", {content, position},
                                                                    this.props.sessionToken, this.props.socketID, question);
                                                            }}
                                                        />
                                                    );
                                                } else if (this.props.whichTab === 2) {
                                                    return (
                                                        <CustomTipGeneration
                                                            onOpen={transformSelection}
                                                            hideTipAndSelection={hideTipAndSelection}
                                                            content={content}
                                                            position={position}
                                                            onConfirmHighlight={(comment, forReal) => {
                                                                if (forReal) {
                                                                    this.updateHighlightInDoc(content, position, comment, highlightID)
                                                                    hideTipAndSelection();
                                                                } else {
                                                                    this.addHighlight({ content, position, comment }, highlightID);
                                                                }
                                                            }}
                                                        />
                                                    )
                                                }
                                            } else {
                                                return (
                                                    <CustomTipImage
                                                        toggleAreaSelection={this.toggleAreaSelection}
                                                        onOpen={transformSelection}
                                                        hideTipAndSelection={hideTipAndSelection}
                                                        content={content}
                                                        position={position}
                                                        onConfirmHighlight={(comment, forReal) => {
                                                            if (forReal) {
                                                                this.updateHighlightInDoc(content, position, comment, highlightID)
                                                                hideTipAndSelection();
                                                            } else {
                                                                this.addHighlight({ content, position, comment }, highlightID);
                                                            }
                                                        }}
                                                    />
                                                );
                                            }
                                        }}
                                        highlightTransform={(
                                            highlight,
                                            index,
                                            setTip,
                                            hideTip,
                                            viewportToScaled,
                                            screenshot,
                                            isScrolledTo
                                        ) => {
                                            const isTextHighlight = !Boolean(
                                                highlight.content && highlight.content.image
                                            );

                                            const component = isTextHighlight ? (
                                                <Highlight
                                                    isScrolledTo={isScrolledTo}
                                                    position={highlight.position}
                                                    comment={highlight.comment}
                                                />
                                            ) : (
                                                <AreaHighlight
                                                    isScrolledTo={isScrolledTo}
                                                    highlight={highlight}
                                                    onChange={(boundingRect) => {
                                                        this.updateHighlight(
                                                            highlight.id,
                                                            { boundingRect: viewportToScaled(boundingRect) },
                                                            { image: screenshot(boundingRect) }
                                                        );
                                                    }}
                                                />
                                            );
                                            return (
                                                <Popup
                                                    popupContent={<HighlightPopup {...highlight} />}
                                                    onMouseOver={(popupContent) =>
                                                        setTip(highlight, (highlight) => popupContent)
                                                    }
                                                    onMouseOut={hideTip}
                                                    key={index}
                                                    children={component}
                                                />
                                            );
                                        }}
                                        highlights={this.state.highlights}
                                    />
                                )}}
                            </PdfLoader>
                        </Box>
                    </div>
                    <div
                        onMouseDown={this.handleMouseDown}
                        style={{
                            cursor: 'ew-resize',
                            width: '0.3rem',
                            backgroundColor: 'grey',
                            height: 'calc(100% + 3rem)',
                            position: 'relative', // Needed to position the icon
                        }}
                    >
                        <span
                            style={{
                                position: 'absolute', // Position the icon absolutely within the divider
                                top: '50%', // Center vertically
                                left: '50%', // Center horizontally
                                transform: 'translate(-50%, -50%)', // Adjust the exact centering
                                fontSize: '12px', // Adjust the size as needed
                                color: 'white', // Color of the icon, change as needed
                                userSelect: 'none', // Prevent text selection
                            }}
                        >
                            <FaArrowsAltH size={'1rem'} color={'black'}/>
                        </span>
                    </div>

                    {this.props.isChatVisible ?
                        <Sidebar
                            stopClaudeResponse={this.stopClaudeResponse}
                            highlights={this.state.highlights}
                            document={this.props.document}
                            sessionToken={this.props.sessionToken}
                            width={100 - this.state.panelWidth}
                            handleChatResponseNoSelection={this.handleChatResponseNoSelection}
                            handleChatResponseSelection={this.handleChatResponseSelection}
                            handleChatFollowUp={this.handleChatFollowUp}
                            socketID={this.props.socketID}
                            deleteHighlight={(highlight, highlightID) => {
                                this.deleteHighlight(highlight, highlightID);
                            }}
                            updateHighlightInDoc={(content, position, comment, highlightID) => {
                                this.updateHighlightInDoc(content, position, comment, highlightID)
                            }}
                        /> : null}
                    {!this.props.isChatVisible ?
                        <Box
                            bgGradient="linear(to-r, gray.100, gray.200)"  // Gradient background
                            borderColor={'gray.300'}
                            borderWidth={'1px'}
                            borderBottomWidth={'0px'}
                            h={'2.75rem'}
                            w={'25rem'}
                            position={'fixed'}
                            bottom={'0'}
                            right={'0'}
                            zIndex={'9999'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            display={'flex'}
                            onClick={() => this.props.toggleIsChatVisible()}
                            _hover={{cursor: 'pointer'}}
                            borderRadius={'10px 10px 0 0'}  // Rounded corners at the top
                            boxShadow={'0px -2px 10px rgba(0,0,0,0.1)'}  // Shadow effect
                        >
                            <Text fontSize="md" fontWeight="medium">Show Chat</Text>
                        </Box> : null}
                </div>
            </FullScreenTemplate>
        )
    }
}

const mapStateToProps = state => ({
    isFullScreen: state.settings.isFullScreen,
    isChatVisible: state.settings.isChatVisible,
    currentChatUserID: state.settings.currentChatUserID,
    currentChatClaudeID: state.settings.currentChatClaudeID,
    currentChatSelectionText: state.settings.currentChatSelectionText,
    currentChatQuestion: state.settings.currentChatQuestion,
    currentChatType: state.settings.currentChatType,
    currentChatResponse: state.settings.currentChatResponse,
    whichTab: state.settings.whichTab,
});

const mapDispatchToProps = {
    addMessageInDocument,
    toggleIsFullScreen,
    toggleIsChatVisible,
    appendToMessageInDocument,
    updateMessageInDocument,
    setHighlightsInDocument,
    setCurrentChatQuestion,
    setCurrentChatUserID,
    setCurrentChatClaudeID,
    setCurrentChatSelectionText,
    setCurrentChatType,
    addToCurrentChatResponse,
    resetCurrentChatResponse,
    setWhichTab,
    setIsPricingModalOpen,
    removeMessageInDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(PDFPage);
