// UrlInputWithTooltip.jsx
import React from 'react';
import { HStack, Box, Tooltip, Icon, InputGroup, Input, InputRightAddon, Button } from '@chakra-ui/react';
import { FiInfo } from 'react-icons/fi';
import { AiOutlineSend } from 'react-icons/ai';

const URLInput = ({ urlInput, setUrlInput, handleSubmit, currentPlaceholder, fade }) => {
    return (
        <HStack width={'100%'} spacing={5}>
            <Box display="inline-block"> {/* Ensures the tooltip and icon align correctly */}
                <Tooltip label="Link to a public Youtube video" placement="top" hasArrow>
                                        <span> {/* Tooltip works with elements that accept onMouseOver and onMouseOut events */}
                                            <Icon as={FiInfo} cursor="pointer" />
                                        </span>
                </Tooltip>
            </Box>
            <InputGroup size='md'>
                <Box position="relative" width="full">
                    <Input borderRadius={0}
                           value={urlInput}
                           onChange={(e) => setUrlInput(e.target.value)}
                           height={'100%'}
                           sx={{
                               _focus: {
                                   borderWidth: '0px', // Adjust thickness on focus
                               },
                           }}
                    >
                    </Input>
                    {urlInput === "" ?
                        <Box
                            as="span"
                            position="absolute"
                            left="2"
                            top="0"
                            right="0"
                            bottom="0"
                            px={2} // Same padding as the Input
                            display="flex"
                            alignItems="center"
                            justifyContent="start"
                            color="gray.400"
                            pointerEvents="none" // Allows click through to the input
                            opacity={fade ? 1 : 0} // Fade in and out
                            transition="opacity 0.75s ease-in-out"
                            whiteSpace="nowrap" // Prevents wrapping
                            overflow="hidden" // Hides overflow content
                            textOverflow="ellipsis" // Adds ellipsis to overflow content
                            maxWidth="calc(100% - 4px)"
                        >
                            {currentPlaceholder}
                        </Box> : null}
                </Box>
                <InputRightAddon as={Button} onClick={handleSubmit} bg={'orange.400'}
                                 borderWidth={0}
                                 sx={{
                                     _hover: {
                                         bg: 'orange.500',
                                         _disabled: {
                                             bg: 'orange.400',
                                         },
                                     },
                                 }}
                                 isDisabled={urlInput === ""}
                >
                    <AiOutlineSend style={{ fontSize: '21px', color: "white" }}/>
                </InputRightAddon>
            </InputGroup>
        </HStack>
    );
};

export default URLInput;
