import {
    Box,
    Stack,
    HStack,
    Heading,
    Text,
    VStack,
    Button,
    useToast,
    useDisclosure,
    useBreakpointValue,
    Image,
    GridItem,
    Grid,
    SliderTrack,
    SliderFilledTrack,
    Tooltip,
    SliderThumb, Slider,
} from '@chakra-ui/react'
import React, {useEffect, useRef} from "react";
import FullScreenTemplate from "../../templates/FullScreenTemplate";
import {useAuth} from "../../redux/AuthProvider";
import LargeWithNewsletter from "../../components/Reuseable/LargeWithNewsletter";
import SignUpScreen1 from "../../components/Modals/SignUp/SignUpScreen1";
import SignInScreen1 from "../../components/Modals/SignIn/SignInScreen1";
import Lottie from "lottie-react";
import affiliateAnimation from "./affiliate-animation.json"
import {useTranslation} from "react-i18next";


export default function Affiliate() {
    const { isOpen: isOpenSignUpScreen1, onOpen: onOpenSignUpScreen1, onClose: onCloseSignUpScreen1 } = useDisclosure();
    const { isOpen: isOpenSignInScreen1, onOpen: onOpenSignInScreen1, onClose: onCloseSignInScreen1 } = useDisclosure();
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';
    const stepsRef = useRef(null);
    const {t} = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigateToSteps = () => {
        if (stepsRef.current) {
            const offsetTop = stepsRef.current.getBoundingClientRect().top + window.pageYOffset;
            const offset = 105; // The amount of pixels you want to stop above the element
            window.scrollTo({ top: offsetTop - offset, behavior: 'smooth' });
        }
    }

    const isStacked = useBreakpointValue({ base: true, sm: true, md: true, lg: false });
    const isGridStacked = useBreakpointValue({ base: true, sm: true, md: false, lg: false });
    const [sliderValue, setSliderValue] = React.useState(20);
    const [showTooltip, setShowTooltip] = React.useState(true);

    return (
        <div>
            <FullScreenTemplate>
                <VStack spacing={20}>
                    {isStacked ? (
                        <VStack width={'100%'}>
                            <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} p={4}>
                                <VStack spacing={10} align={'center'} bg={'white'} mt={4}>
                                    <Heading as="h1" fontSize="6xl" textAlign="center">
                                        {t("Earn 60% referral commission")}
                                    </Heading>
                                    <Text fontSize={19} color={'gray.600'} textAlign="center">
                                        {t("Join our affiliate program and receive a 60% commission on all payments within the first 12 months for paying customers you refer to us!")}
                                    </Text>
                                    <Stack spacing={6} direction={'row'} mt={1}>
                                        <Button
                                            rounded={'full'}
                                            px={6}
                                            colorScheme={'orange'}
                                            bg={'orange.400'}
                                            _hover={{ bg: 'orange.500' }}
                                            onClick={() => {
                                                window.location.href = 'https://honeybear-ai.getrewardful.com/signup'
                                            }}>
                                            {t("Start earning today")}
                                        </Button>
                                        <Button rounded={'full'} px={6} onClick={() => {
                                            navigateToSteps();
                                        }}>
                                            {t("Learn more")}
                                        </Button>
                                    </Stack>
                                </VStack>
                            </Box>
                            <Box width={'100%'} height={'50vh'} bg={'rgba(245,245,245,1)'} mt={8}>
                                <Lottie
                                    animationData={affiliateAnimation}
                                    loop={true}
                                    autoplay={true}
                                    style={{ width: '100%', height: '100%' }}
                                />
                            </Box>
                        </VStack>
                    ) : (
                        <HStack width={'100%'} height={'75%'}>
                            <Box bg={'white'} width={'50%'} height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <VStack spacing={breakpoint === 'lg' ? 6 : 8} width={'75%'} height={'50%'} align={'start'} bg={'white'}>
                                    <Heading as="h1" fontSize={breakpoint === 'lg' ? '4xl' : '6xl'}>
                                        {t("Earn 60% referral commission")}
                                    </Heading>
                                    <Text fontSize={breakpoint === 'lg' ? 16 : 19} color={'gray.600'}>
                                        {t("Join our affiliate program and receive a 60% commission on all payments within the first 12 months for paying customers you refer to us!")}
                                    </Text>
                                    <Stack spacing={6} direction={'row'} mt={1}>
                                        <Button
                                            rounded={'full'}
                                            px={6}
                                            colorScheme={'orange'}
                                            bg={'orange.400'}
                                            _hover={{ bg: 'orange.500' }}
                                            onClick={() => {
                                                window.location.href = 'https://honeybear-ai.getrewardful.com/signup'
                                            }}>
                                            {t("Start earning today")}
                                        </Button>
                                        <Button rounded={'full'} px={6} onClick={() => {
                                            navigateToSteps();
                                        }}>
                                            {t("Learn more")}
                                        </Button>
                                    </Stack>
                                </VStack>
                            </Box>
                            <Box width={'50%'} height={'100%'} bg={'rgba(245,245,245,1)'}>
                                <Lottie
                                    animationData={affiliateAnimation}
                                    loop={true}
                                    autoplay={true}
                                    style={{ width: '100%', height: '100%' }}
                                />
                            </Box>
                        </HStack>
                    )}
                    <VStack width={'60%'} bg={'white'} spacing={8} align={'start'} ref={stepsRef}>
                        <Grid templateColumns={isGridStacked ? "1fr" : "repeat(3, 1fr)"} gap={10} width="100%">
                            <GridItem>
                                <VStack align={'start'}>
                                    <Heading as="h3" fontSize="xl" fontWeight="bold">
                                        {t("STEP ONE")}
                                    </Heading>
                                    <Box borderBottom="2px" borderColor="black" width="100%" mb={2}></Box>
                                    <Text fontSize="2xl" fontWeight="bold">
                                        {t("Sign up for our program")}
                                    </Text>
                                    <Text fontSize="md" color={'gray.600'}>
                                        {t("Sign up for our affiliate program and get your unique referral link.")}
                                    </Text>
                                </VStack>
                            </GridItem>
                            <GridItem>
                                <VStack align={'start'}>
                                    <Heading as="h3" fontSize="xl" fontWeight="bold">
                                        {t("STEP TWO")}
                                    </Heading>
                                    <Box borderBottom="2px" borderColor="black" width="100%" mb={2}></Box>
                                    <Text fontSize="2xl" fontWeight="bold">
                                        {t("Share your referral link")}
                                    </Text>
                                    <Text fontSize="md" color={'gray.600'}>
                                        {t("Share your referral link with your audience, colleagues, friends, or anyone else!")}
                                    </Text>
                                </VStack>
                            </GridItem>
                            <GridItem>
                                <VStack align={'start'}>
                                    <Heading as="h3" fontSize="xl" fontWeight="bold">
                                        {t("STEP THREE")}
                                    </Heading>
                                    <Box borderBottom="2px" borderColor="black" width="100%" mb={2}></Box>
                                    <Text fontSize="2xl" fontWeight="bold">
                                        {t("Get paid")}
                                    </Text>
                                    <Text fontSize="md" color={'gray.600'}>
                                        {t("We'll send you commission via PayPal on the 1st of every month.")}
                                    </Text>
                                </VStack>
                            </GridItem>
                        </Grid>
                    </VStack>
                    <VStack width={'60%'}>
                        <Heading as="h1" fontSize="5xl" fontWeight="600" textAlign={'center'}>
                            <Box
                                as="span"
                                bgGradient="linear(to-r, purple.500, pink.500)"
                                bgClip="text"
                            >
                                {t("How much you could earn")}
                            </Box>
                        </Heading>
                        <Text fontSize={14} color={'gray'} mt={3} mb={4} fontWeight="500" textAlign={'center'}>
                            (Each referral is actively subscribed for 12 months)
                        </Text>
                        <HStack width={'100%'} align={'center'} justify={'center'} spacing={6}>
                            <Slider
                                id='slider'
                                defaultValue={20}
                                min={0}
                                max={100}
                                colorScheme='teal'
                                onChange={(v) => setSliderValue(v)}
                                onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                            >
                                <SliderTrack bg='red.100' h={2} borderColor={'lightgray'} borderWidth={0.25}
                                             shadow={`1.5px 1.8px 0px lightgray`}>
                                    <SliderFilledTrack bg='tomato' />
                                </SliderTrack>
                                <Tooltip
                                    hasArrow
                                    bg="black"
                                    py={1}
                                    px={2}
                                    color="white"
                                    fontWeight={'bold'}
                                    placement="bottom"
                                    isOpen={true}
                                    label={`${sliderValue} referrals`}
                                    fontSize={'md'}
                                >
                                    <SliderThumb boxSize={6} color={'black'} borderColor={'lightgray'} borderWidth={0.25}
                                                 shadow={`1.5px 1.8px 0px lightgray`}/>
                                </Tooltip>
                                {/*<Tooltip*/}
                                {/*    hasArrow*/}
                                {/*    bg="black"*/}
                                {/*    py={1}*/}
                                {/*    px={2}*/}
                                {/*    color="white"*/}
                                {/*    placement="top"*/}
                                {/*    isOpen={true}*/}
                                {/*    label={t("Conversions")}*/}
                                {/*    fontSize={'xs'}*/}
                                {/*>*/}
                                {/*    <SliderThumb boxSize={6} color={'black'} borderColor={'lightgray'} borderWidth={0.25}*/}
                                {/*                 shadow={`1.5px 1.8px 0px lightgray`}/>*/}
                                {/*</Tooltip>*/}
                            </Slider>
                            <Text fontSize={isMobile ? 'xl' : '2xl'} fontWeight="bold" textAlign={'center'}>
                                ${sliderValue * 72}
                            </Text>
                        </HStack>
                    </VStack>
                    <Box width={'60%'} bgGradient="linear(to-b, blue.900, black)" borderRadius={30}
                         py={20} textAlign="center" px={10} mt={10}>
                        <VStack spacing={6}>
                            <Heading as="h2" fontSize="3xl" color="white">
                                {t("Ready to start earning?")}
                            </Heading>
                            <Text fontSize="lg" color="gray.300">
                                {t("Join our affiliate program to earn a 60% commission for up to 12 months!")}
                            </Text>
                            <Button
                                rounded="full"
                                px={8}
                                mt={1}
                                colorScheme="whiteAlpha"
                                bg="white"
                                color="#0d1117"
                                onClick={() => {
                                    window.location.href = 'https://honeybear-ai.getrewardful.com/signup'
                                }}
                                _hover={{ bg: 'gray.300' }}
                            >
                                {t("Become an affiliate")}
                            </Button>
                        </VStack>
                    </Box>
                </VStack>
                <Box height="auto" width="100%" mt={16}>
                    <LargeWithNewsletter />
                </Box>
            </FullScreenTemplate>
            {/*<Box height={!isStacked  ? '40rem' : !isGridStacked ? '60rem' : breakpoint !== 'base' ? '88rem' : '105rem'}/>*/}
            {/*<LargeWithNewsletter />*/}
            <SignUpScreen1 isOpenSignUpScreen1={isOpenSignUpScreen1} onCloseSignUpScreen1={onCloseSignUpScreen1} onOpenSignInScreen1={onOpenSignInScreen1}/>
            <SignInScreen1 isOpenSignInScreen1={isOpenSignInScreen1} onCloseSignInScreen1={onCloseSignInScreen1} onOpenSignUpScreen1={onOpenSignUpScreen1}/>
        </div>
    )
}
