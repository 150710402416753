import React, { useState } from "react";
import {Box, Button, HStack, useToast, VStack} from "@chakra-ui/react";
import {useDispatch} from "react-redux";
import {
    setAskContent, setFollowUpContent,
    toggleIsAskVisible,
    toggleIsAskVisibleTrue, toggleIsFollowUpVisibleFalse,
    toggleIsFollowUpVisibleTrue
} from "../../redux/reducers";
import {useAuth} from "../../redux/AuthProvider";

const CustomTipGeneration = ({ onConfirmHighlight, hideTipAndSelection, content, position }) => {
    const [noteText, setNoteText] = useState('');
    // const [questionText, setQuestionText] = useState('');
    const [showNoteBox, setShowNoteBox] = useState(false);
    // const [showQuestionBox, setShowQuestionBox] = useState(false);
    const dispatch = useDispatch();
    const auth = useAuth();

    const toggleNoteBox = () => {
        setShowNoteBox(!showNoteBox);
    };

    // const toggleQuestionBox = () => {
    //     setShowQuestionBox(false);
    //     hideTipAndSelection();
    //     dispatch(toggleIsAskVisibleTrue());
    //     dispatch(setAskContent({content: content, position: position}))
    //     dispatch(toggleIsFollowUpVisibleFalse());
    //     dispatch(setFollowUpContent(null));
    // }

    const handleNoteTextChange = (e) => {
        setNoteText(e.target.value);
    };

    // const handleQuestionTextChange = (e) => {
    //     setQuestionText(e.target.value);
    // }
    //
    const handleConfirmHighlight = (forReal) => {
        onConfirmHighlight({ text: noteText }, forReal);
        toggleNoteBox();
    };
    //
    // const handleConfirmAsk = () => {
    //     onConfirmAsk(questionText);
    //     toggleQuestionBox();
    // }

    const buttonStyles = {
        backgroundColor: "black",
        fontSize: 'xs',
        color: "white",
        _hover: { backgroundColor: "gray.600" },
        borderRadius: '10px',
    };

    const textAreaStyles = {
        width: '13.1rem',
        height: 'auto',
        maxHeight: '250px',
        minHeight: '4.5rem',
        wrap: 'soft',
        borderRadius: '10px',
        padding: '8px',
        fontSize: '0.9rem',
        overflowY:"auto",  // No vertical scrollbar
        resize:"none",  // Disable manual resizing
        _focus: { boxShadow: 'none', outline: 'none' },
    };

    return (
        <HStack bg={'black'} borderRadius={'10px'}>
            {!showNoteBox && (
                <Button onClick={() => {
                    handleConfirmHighlight(false);
                }} {...buttonStyles}>
                    Add Note
                </Button>
            )}
            {/*{!showQuestionBox && !showNoteBox && (*/}
            {/*    <Button onClick={toggleQuestionBox} {...buttonStyles}>*/}
            {/*        Ask*/}
            {/*    </Button>*/}
            {/*)}*/}

            {showNoteBox && (
                <VStack backgroundColor="black" borderRadius={'10px'} padding={'0.5rem'} spacing={2} align={"flex-end"}>
                    <textarea
                        placeholder="Add your note here..."
                        value={noteText}
                        autoFocus={true}
                        onChange={handleNoteTextChange}
                        style={textAreaStyles}
                        onInput={(e) => {  // Auto-expand as text overflows
                            e.currentTarget.style.height = 'auto';
                            e.currentTarget.style.height = (e.currentTarget.scrollHeight) + 'px';
                        }}
                        onFocus={(e) => {
                            e.currentTarget.style.outline = 'none';
                        }}
                    />
                    <Button size="xs" colorScheme="blue" onClick={() => {
                        handleConfirmHighlight(true);
                    }}>
                        Save
                    </Button>
                </VStack>
            )}

            {/*{showQuestionBox && (*/}
            {/*    <VStack backgroundColor="black" borderRadius={'10px'} padding={'0.5rem'} spacing={2} align={"flex-end"}>*/}
            {/*        <textarea*/}
            {/*            placeholder="What question do you have?"*/}
            {/*            value={questionText}*/}
            {/*            onChange={handleQuestionTextChange}*/}
            {/*            style={textAreaStyles}*/}
            {/*            onInput={(e) => {  // Auto-expand as text overflows*/}
            {/*                e.currentTarget.style.height = 'auto';*/}
            {/*                e.currentTarget.style.height = (e.currentTarget.scrollHeight) + 'px';*/}
            {/*            }}*/}
            {/*            onFocus={(e) => {*/}
            {/*                e.currentTarget.style.outline = 'none';*/}
            {/*            }}*/}
            {/*        />*/}
            {/*        <Button size="xs" colorScheme="blue" onClick={handleConfirmAsk} isDisabled={questionText === ""}>*/}
            {/*            Ask!*/}
            {/*        </Button>*/}
            {/*    </VStack>*/}
            {/*)}*/}

            {/*{!showNoteBox && !showQuestionBox && (*/}
            {/*    <>*/}
            {/*        <Button onClick={onConfirmSummarize} {...buttonStyles} isDisabled={auth.isThinking || auth.isTyping}>*/}
            {/*            Summarize*/}
            {/*        </Button>*/}
            {/*        <Button onClick={onConfirmExplain} {...buttonStyles} isDisabled={auth.isThinking || auth.isTyping}>*/}
            {/*            Explain*/}
            {/*        </Button>*/}
            {/*    </>*/}
            {/*)}*/}
        </HStack>
    );
};

export default CustomTipGeneration;
