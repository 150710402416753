import axios from "axios";
import {SERVER_IP} from "./constants";

export const fetchDocuments = async (userID) => {
    console.log("fetch documents userID", userID)
    try {
        const response = await axios.post(SERVER_IP + '/list_papers', {
            "user_id" : userID,
        });
        console.log("response for fetch documents", response)
        if (response.data.status === 'success') {
            const documentList = [];
            for (let i = 0; i < response.data.documents_sorted.length; i++) {
                documentList.push({
                    "id" : response.data.documents_sorted[i].id,
                    "name" : response.data.documents_sorted[i].name,
                    "url" : response.data.documents_sorted[i].url,
                    "highlights" : [],
                    "messages" : [],
                });
            }
            return {
                "status" : 200,
                "documents" : documentList,
            }
        }
    } catch (error) {
        console.log("/list_papers error", error);
        return null;
    }
}

export const fetchVideos = async (userID) => {
    console.log("fetch videos userID", userID)
    try {
        const response = await axios.post(SERVER_IP + '/videos/list_videos', {
            "user_id" : userID,
        });
        console.log("response for fetch videos", response)
        if (response.data.status === 'success') {
            const documentList = [];
            for (let i = 0; i < response.data.videos_sorted.length; i++) {
                documentList.push({
                    "id" : response.data.videos_sorted[i].id,
                    "name" : response.data.videos_sorted[i].name,
                    "url" : response.data.videos_sorted[i].yt_link,
                    "thumbnail_url" : response.data.videos_sorted[i].thumbnail_url,
                    "highlights" : [],
                    "messages" : [],
                });
            }
            return {
                "status" : 200,
                "videos" : documentList,
            }
        }
    } catch (error) {
        console.log("/list_videos error", error);
        return null;
    }
}

export const fetchGenerations = async (userID) => {
    console.log("fetch generations userID", userID)
    try {
        const response = await axios.post(SERVER_IP + '/pdf/list_generations', {
            "user_id" : userID,
        });
        console.log("response for fetch generations", response)
        if (response.data.status === 200) {
            const documentListGeneration = [];
            for (let i = 0; i < response.data.message.length; i++) {
                documentListGeneration.push({
                    "id" : response.data.message[i].generation_id,
                    "name" : response.data.message[i].generation_title,
                    "lastOpenTime" : response.data.message[i].last_open_time,
                    "messages" : [],
                    "files" : [],
                });
            }
            return {
                "status" : 200,
                "generations" : documentListGeneration,
            }
        }
    } catch (error) {
        console.log("/list_generations error", error);
        return null;
    }
}

export const checkProPlan = async (userID) => {
    console.log("/subscription/is-premium-user userID", userID)
    try {
        const response = await axios.post(SERVER_IP + '/subscription/is-premium-user', {
            "user_id" : userID,
        });
        console.log("response for /subscription/is-premium-user", response)
        if (response.data.status === 200) {
            return {
                "status" : 200,
                "isProPlan" : response.data.message.is_premium,
            }
        }
    } catch (error) {
        console.log("/subscription/is-premium-user error", error);
        return null;
    }
}

export const manageSubscription = async (userID) => {
    console.log("manage_subscription_clicked")
    console.log("/subscription/manage-subscription userID", userID)
    try {
        const response = await axios.post(SERVER_IP + '/subscription/manage-subscription', {
            "user_id" : userID,
        });
        console.log("response for /subscription/manage-subscription", response)
        if (response.data.status === 200) {
            return {
                "status" : 200,
                "url" : response.data.message.url,
            }
        }
    } catch (error) {
        console.log("/subscription/manage-subscription error", error);
        return null;
    }
}

export const maxOutUsage = async (userID, keysToMax) => {
    console.log("maxed_out_usage")
    console.log("/subscription/max-out-user-free-usage userID", userID)
    try {
        const response = await axios.post(SERVER_IP + '/subscription/max-out-user-free-usage', {
            "user_id" : userID,
            "keys_to_max" : keysToMax,
        });
        console.log("response for /subscription/max-out-user-free-usage", response)
        if (response.data.status === 200) {
            return {
                "status" : 200,
            }
        }
    } catch (error) {
        console.log("/subscription/max-out-user-free-usage error", error);
        return null;
    }
}

