'use client'

import {
    Box,
    VStack,
    HStack,
    Text,
    Image,
    useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Marquee from "react-fast-marquee";

export default function LogoCarousel() {
    const { t } = useTranslation();
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';

    const logos = [
        { src: "/duke_logo.jpg", alt: "Duke University Logo", height: "3.65rem" },
        { src: "/harvard_logo.jpg", alt: "Harvard Logo", height: "4rem" },
        { src: "/princeton_logo.jpg", alt: "Princeton Logo", height: "2.9rem" },
        { src: "/brown_logo.jpg", alt: "Brown Logo", height: "2.3rem" },

        { src: "/melbourne_logo.png", alt: "University of Melbourne Logo", height: "3.85rem" },
        { src: "/mit_logo.png", alt: "MIT Logo", height: "3.2rem" },
        { src: "/yale_logo.png", alt: "Yale Logo", height: "3.4rem" },
        { src: "/stanford_logo.png", alt: "Stanford Logo", height: "2.8rem" },
        { src: "/oxford_logo.svg", alt: "University of Oxford Logo", height: "3.8rem" },
    ];

    return (
        <Box
            width="100vw"
            mb="3.3rem"
            justify="center"
            align="center"
            flexDirection={isMobile ? 'column' : 'row'}
            mt={1}
            display="flex"
            justifyContent="center"
            alignItems={isMobile ? 'center' : 'flex-start'}
            py="0rem"
            overflow="hidden"
            position="relative"
        >
            <VStack spacing={10}>
                <Text fontSize={20} fontWeight="600">
                    {t("Trusted by thousands of students & researchers at 100+ universities")}
                </Text>
                {isMobile ? (
                    <VStack spacing={12} bg="white">
                        {logos.slice(0,4).map((logo, index) => (
                            <Image key={index} src={logo.src} alt={logo.alt} height={logo.height} />
                        ))}
                    </VStack>
                ) : (
                    <Box
                        display="flex"
                        overflow="hidden"
                        width="100vw"
                    >
                        <Marquee speed={50} autoFill={true} gradient={true} gradientWidth={150}>
                            {logos.map((logo, index) => (
                                <Image key={index} src={logo.src} alt={logo.alt} height={logo.height} mr={14}/>
                            ))}
                        </Marquee>
                    </Box>
                )}
            </VStack>
        </Box>
    );
}
