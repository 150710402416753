import {
    Box,
    Flex,
    Heading,
    Text,
    Stack,
    Container,
    Avatar,
    useColorModeValue,
} from '@chakra-ui/react'
import {useTranslation} from "react-i18next";

const Testimonial = (props) => {
    const { children } = props

    return <Box>{children}</Box>
}

const TestimonialContent = (props) => {
    const { children } = props

    return (
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            boxShadow={'lg'}
            p={8}
            rounded={'xl'}
            align={'center'}
            justify={'center'}
            pos={'relative'}
            w={'20rem'}
            h={'13rem'}
            overflow={'auto'} >
            {children}
        </Stack>
    )
}

const TestimonialHeading = (props) => {
    const { children } = props

    return (
        <Heading as={'h3'} fontSize={'xl'}>
            {children}
        </Heading>
    )
}

const TestimonialText = (props) => {
    const { children } = props

    return (
        <Text
            textAlign={'center'}
            color={'black'}
            fontSize={'16px'}>
            {children}
        </Text>
    )
}

const TestimonialAvatar = ({
                               src,
                               name,
                               title,
                           }) => {
    return (
        <Flex align={'center'} mt={8} direction={'column'}>
            <Avatar src={src} mb={4} size='lg' border='1px' borderColor='white'/>
            <Stack spacing={1} align={'center'}>
                <Text fontWeight={600} color={'white'}>{name}</Text>
                <Text fontSize={'sm'} color={'white'}>
                    {title}
                </Text>
            </Stack>
        </Flex>
    )
}

export default function WithSpeechBubbles() {
    const {t} = useTranslation();

    return (
        <Box bgGradient="linear(to-b, blue.900, black)">
            <Container maxW={'7xl'} pt={14} pb={16} as={Stack} spacing={12}>
                <Stack spacing={5} align={'center'}>
                    <Heading color={'white'} fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>{t("Testimonials")}</Heading>
                    <Text color={'white'}>{t("Loved by thousands of users!")}</Text>
                </Stack>
                <Stack
                    direction={{ base: 'column', md: 'row' }}
                    spacing={{ base: 10, md: 4, lg: 10 }} justify={'center'}>
                    <Testimonial>
                        <TestimonialContent>
                            <TestimonialText>
                                {t("\"After using honeybear.ai quite a bit, I've noticed the responses are better quality than ChatGPT responses. Very impressive.\"")}
                            </TestimonialText>
                        </TestimonialContent>
                        <TestimonialAvatar
                            src='/Yash_Headshot.jpeg'
                            name={'Yash M.'}
                            title={'Jurassic Capital'}
                        />
                    </Testimonial>
                    <Testimonial>
                        <TestimonialContent>
                            <TestimonialText>
                                {t("\"As a STEM student consistently immersed in reading research papers and conducting literature reviews, honeybear.ai has increased the efficiency of my studying & research!\"")}
                            </TestimonialText>
                        </TestimonialContent>
                        <TestimonialAvatar
                            src={'/Cecile_Headshot.jpg'}
                            name={'Cecile S.'}
                            title={'Student, Brown University'}
                        />
                    </Testimonial>
                    <Testimonial>
                        <TestimonialContent>
                            <TestimonialText>
                                {t("\"I've been using honeybear.ai every day and it's fantastic!\"")}
                            </TestimonialText>
                        </TestimonialContent>
                        <TestimonialAvatar
                            src='/Obi_Headshot.JPG'
                            name={'Obi O.'}
                            title={'Student Researcher, Cornell University'}
                        />
                    </Testimonial>
                </Stack>
            </Container>
        </Box>
    )
}
