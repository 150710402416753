import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';

const bounce = keyframes`
  0%, 100% {
    transform: scale(0.0);
  }
  50% {
    transform: scale(1.0);
  }
`;

const Dot = ({ delay }) => (
    <Box
        as="span"
        mx="0.25rem"
        bg={useColorModeValue('black', 'white')}
        borderRadius="50%"
        display="inline-block"
        width="0.5rem"
        height="0.5rem"
        animation={`${bounce} 1.4s infinite ease-in-out both`}
        animationDelay={delay}
    />
);

export const ThreeDotLoading = () => (
    <Box as="div" display="inline-block">
        <Dot delay="-0.32s" />
        <Dot delay="-0.16s" />
        <Dot delay="0s" />
    </Box>
);
