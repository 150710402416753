import axios from "axios";
import {SERVER_IP} from "./constants";

const questionTextDecider = (sessionToken, pageNumber, selectionText, question, type, socketID, messageHistory) => {
    if (type === 'explain_selection') {
        return {
            "session_token": sessionToken,
            "page_number": pageNumber,
            "selection_text": selectionText,
            "question": question,
            "action": type,
            "socketio_sid": socketID,
            "message_history": messageHistory,
        }
    } else if (type === "summarize_selection") {
        return {
            "session_token": sessionToken,
            "page_number": pageNumber,
            "selection_text": selectionText,
            "question": question,
            "action": type,
            "socketio_sid": socketID,
            "message_history": messageHistory,
        }
    } else if (type === "ask_selection") {
        return {
            "session_token": sessionToken,
            "page_number": pageNumber,
            "selection_text": selectionText,
            "question": question,
            "action": type,
            "socketio_sid": socketID,
            "message_history": messageHistory,
        }
    } else if (type === "ask_image") {
        return {
            "session_token": sessionToken,
            "page_number": pageNumber,
            "selection_text": selectionText,
            "question": question,
            "action": type,
            "socketio_sid": socketID,
            "message_history": messageHistory,
        }
    } else if (type === "summarize_page") {
        return {
            "session_token": sessionToken,
            "page_number": pageNumber,
            "selection_text": selectionText,
            "question": question,
            "action": type,
            "socketio_sid": socketID
        }
    } else if (type === "summarize_paper") {
        return {
            "session_token": sessionToken,
            "page_number": pageNumber,
            "selection_text": selectionText,
            "question": question,
            "action": type,
            "socketio_sid": socketID
        }
    } else if (type === "general_question") {
        return {
            "session_token": sessionToken,
            "page_number": pageNumber,
            "selection_text": selectionText,
            "question": question,  // put question here
            "action": type,
            "socketio_sid": socketID,
            "message_history": messageHistory,
            "return_prompt": SERVER_IP !== 'https://3.89.226.93.nip.io'
        }
    } else if (type === "follow_up") {
        return {
            "message_responding_to":
                {
                    user:'Claude',
                    response: selectionText
                },
            "question": question,
            "session_token": sessionToken,
            "socketio_sid": socketID,
            "action": type,
            "page_number": pageNumber,
            "message_history": messageHistory,
            "return_prompt": SERVER_IP !== 'https://3.89.226.93.nip.io'
        }
    }
}


export const getClaudeResponse = async (sessionToken, pageNumber, selectionText, question, type, socketID, toast, messageHistory) => {
    // for (let message of messageHistory) {
    //     if (message.type === 'ask_image' && message.user === 'User') {
    //         try {
    //             message.selection_text = await convertToBase64(message.selection_text);
    //             console.log("converted image to base64", message.selection_text)
    //         } catch (error) {
    //             console.log("convertToBase64 error", error);
    //         }
    //     }
    // }

    const requestBody = questionTextDecider(sessionToken, pageNumber, selectionText, question, type, socketID, messageHistory);
    console.log("request body", requestBody)

    try {
        const response = await axios.post(SERVER_IP + '/explain2', requestBody);
        console.log("response from claude", response)
        if (response.data.status === 401) {
            console.log("SESSION EXPIRED")
            toast({
                title: "Session expired. Please RELOAD the page to continue chatting!",
                status: "error",
                duration: 5500,
                isClosable: true,
                position: "top"
            })
        }
        else if (response.data.status === 402 && response.data.message === "free tier exceeded. Please subscribe to continue using the service") {
            toast({
                title: "(Free Plan) Chat Limit Exceeded!",
                status: "info",
                duration: 9000,
                isClosable: true,
                position: "top",
            });
            return "free tier exceeded. Please subscribe to continue using the service";
        }
        else if (response.data.status === 429) {
            toast({
                title: "Rate limit exceeded. Please try again in a few minutes.",
                status: "error",
                duration: 5500,
                isClosable: true,
                position: "top"
            })
        }
        else if (response.status === 404) {
            toast({
                title: "Session expired. Please RELOAD the page to continue chatting!",
                status: "error",
                duration: 5500,
                isClosable: true,
                position: "top"
            })
        }
        else if (response.status === 405) {
            toast({
                title: "Oops, something is wrong with this image. Please try again.",
                status: "error",
                duration: 5500,
                isClosable: true,
                position: "top"
            })
        }
        else if (response.status === 200) {
            return response.data.text;
        }
        else {
            toast({
                title: "We’re experiencing exceptionally high demand. Please hang tight as we work on scaling our systems.",
                status: "error",
                duration: 5500,
                isClosable: true,
                position: "top"
            })
            throw new Error('getClaudeResponse error');
        }
    } catch (error) {
        toast({
            title: "We’re experiencing exceptionally high demand. Please hang tight as we work on scaling our systems.",
            status: "error",
            duration: 5500,
            isClosable: true,
            position: "top"
        })
        console.log("getClaudeResponse error", error);
    }
}

export const getClaudeResponseVideo = async (sessionToken, pageNumber, selectionText, question, type, socketID, toast, messageHistory) => {
    // for (let message of messageHistory) {
    //     if (message.type === 'ask_image' && message.user === 'User') {
    //         try {
    //             message.selection_text = await convertToBase64(message.selection_text);
    //             console.log("converted image to base64", message.selection_text)
    //         } catch (error) {
    //             console.log("convertToBase64 error", error);
    //         }
    //     }
    // }

    const requestBody = questionTextDecider(sessionToken, pageNumber, selectionText, question, type, socketID, messageHistory);
    console.log("request body", requestBody)

    try {
        const response = await axios.post(SERVER_IP + '/videos/explain2', requestBody);
        console.log("response from claude", response)
        if (response.data.status === 401) {
            console.log("SESSION EXPIRED")
            toast({
                title: "Session expired. Please RELOAD the page to continue chatting!",
                status: "error",
                duration: 5500,
                isClosable: true,
                position: "top"
            })
        }
        else if (response.data.status === 402 && response.data.message === "free tier exceeded. Please subscribe to continue using the service") {
            toast({
                title: "(Free Plan) Chat Limit Exceeded!",
                status: "info",
                duration: 9000,
                isClosable: true,
                position: "top",
            });
            return "free tier exceeded. Please subscribe to continue using the service";
        }
        else if (response.data.status === 429) {
            toast({
                title: "Rate limit exceeded. Please try again in a few minutes.",
                status: "error",
                duration: 5500,
                isClosable: true,
                position: "top"
            })
        }
        else if (response.status === 404) {
            toast({
                title: "Session expired. Please RELOAD the page to continue chatting!",
                status: "error",
                duration: 5500,
                isClosable: true,
                position: "top"
            })
        }
        else if (response.status === 405) {
            toast({
                title: "Oops, something is wrong with this image. Please try again.",
                status: "error",
                duration: 5500,
                isClosable: true,
                position: "top"
            })
        }
        else if (response.status === 200) {
            return response.data.text;
        }
        else {
            toast({
                title: "We’re experiencing exceptionally high demand. Please hang tight as we work on scaling our systems.",
                status: "error",
                duration: 5500,
                isClosable: true,
                position: "top"
            })
            throw new Error('getClaudeResponse error');
        }
    } catch (error) {
        toast({
            title: "We’re experiencing exceptionally high demand. Please hang tight as we work on scaling our systems.",
            status: "error",
            duration: 5500,
            isClosable: true,
            position: "top"
        })
        console.log("getClaudeResponse error", error);
    }
}

export const postChatPair = async (listOfMessages, userID, fileID, isLoggedIn) => {
    const requestBody = {
        "new_messages": listOfMessages,
        "user_id": isLoggedIn ? userID : "11111111-1111-1111-1111-111111111111",
        "file_id": fileID
    }
    try {
        const response = await axios.post(SERVER_IP + '/pdf/chat', requestBody);
        console.log("response from claude post chat", response)

        // if (response.status === 200) {
        //     return response.data.text;
        // } else {
        //     throw new Error('getClaudeResponse error');
        // }
    } catch (error) {
        console.log("post chat error", error);
    }
}

export const postChatPairVideo = async (listOfMessages, userID, fileID, isLoggedIn) => {
    const requestBody = {
        "new_messages": listOfMessages,
        "user_id": isLoggedIn ? userID : "11111111-1111-1111-1111-111111111111",
        "file_id": fileID
    }
    try {
        const response = await axios.post(SERVER_IP + '/videos/chat', requestBody);
        console.log("response from claude post chat video", response)

        // if (response.status === 200) {
        //     return response.data.text;
        // } else {
        //     throw new Error('getClaudeResponse error');
        // }
    } catch (error) {
        console.log("post chat video error", error);
    }
}

export const transferFromGuest = async (userID, fileID) => {
    const requestBody = {
        "user_id": userID,
        "file_id": fileID
    }
    try {
        const response = await axios.post(SERVER_IP + '/pdf/transfer_from_guest', requestBody);
        console.log("response from transfer from guest", response)
    } catch (error) {
        console.log("transfer from guest error", error);
    }
}

// export const getClaudeFollowUp = async (message_responding_to, follow_up_question, sessionToken, socketID) => {
//     const requestBody = {
//         "message_responding_to": message_responding_to,
//         "follow_up_question": follow_up_question,
//         "session_token": sessionToken,
//         "socketio_sid": socketID
//     }
//
//     try {
//         const response = await axios.post(SERVER_IP + '/follow_up', requestBody);
//         console.log("response from getClaudeFollowUp ", response)
//         if (response.status === 200) {
//             return response.data.text;
//         } else {
//             throw new Error('getClaudeFollowUp error');
//         }
//     } catch (error) {
//         console.log("getClaudeFollowUp error", error);
//     }
// }


export const contactRoute = async (type, message) => {
    try {
        const response = await axios.post(SERVER_IP + '/contact_us', {
            "type" : type,
            "message" : message,
        });
        console.log("response for contact us", response)
        if (response.data.status === 200) {
            return {
                "status" : 200,
            }
        }
    } catch (error) {
        console.log("/contact_us error", error);
        return null;
    }
}

export const setFeatureFlag = async (userID, featureFlag, featureFlagValue) => {
    try {
        const response = await axios.post(SERVER_IP + '/set_feature_flag', {
            "user_id" : userID,
            "feature_flag" : featureFlag,
            "feature_flag_value" : featureFlagValue,
        });
        console.log("response for /set_feature_flag", response)
        if (response.data.status === 200) {
            return {
                "status" : 200,
            }
        }
    } catch (error) {
        console.log("/set_feature_flag error", error);
        return null;
    }
}

export const getFeatureFlag = async (userID, featureFlag) => {
    try {
        const response = await axios.post(SERVER_IP + '/feature_flag', {
            "user_id" : userID,
            "feature_flag" : featureFlag,
        });
        console.log("response for /get_feature_flag", response)
        if (response.data.status === 200) {
            return {
                "status" : 200,
                "feature_flag" : response.data.message.show_user_rewardful_popup_june_3,
            }
        } else {
            return null;
        }
    } catch (error) {
        console.log("/get_feature_flag error", error);
        return null;
    }
}



