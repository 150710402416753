import {
    Box,
    Text,
    Image,
    HStack,
    Avatar,
    useToast,
    IconButton,
    Icon,
    Heading,
    ListItem,
    UnorderedList,
    OrderedList,
    Td,
    Th,
    Table,
    Thead,
    Tbody,
    Tr,
    useBreakpointValue,
    VStack,
    TabPanel,
    TabList,
    TabPanels,
    Tabs,
    Tab,
} from "@chakra-ui/react";
import {whichMessageHeader} from "../../utils/pdf-page-helpers";
import React, {useEffect, useState} from "react";
import {ThreeDotLoading} from "../Reuseable/ThreeDotLoading";
import {useAuth} from "../../redux/AuthProvider";
import FollowUpButton from "./FollowUpButton";
import lightTheme from "../../utils/styles";
import {FiCopy, FiCornerDownRight} from "react-icons/fi";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {markdownComponents} from "../../utils/constants";
import 'react-quill/dist/quill.snow.css'; // ES6
import '../../custom-quill.css'
import Lottie from "lottie-react";
import lottieWriting from './lottie-writing.json';
import {useSelector} from "react-redux";
import LoadingBar from "../../pages/HomePage/LoadingBar";
import {useTranslation} from "react-i18next";

export const MessageBubbleGeneration = ({ message, isLastMessage, userBgColor, claudeBgColor, claudeColor,
                                  lastMessageRef, sessionToken, socketID, chatContainerRef, isSecondToLastMessage, secondToLastMessageRef }) => {
    const auth = useAuth();
    const toast = useToast();
    const currentGenerationProgress = useSelector(state => state.settings.currentGenerationProgress);
    const currentGenerationWordCount = useSelector(state => state.settings.currentGenerationWordCount);
    const {t} = useTranslation();

    const [minHeight, setMinHeight] = useState('45rem');
    const [minHeightSubtracted, setMinHeightSubtracted] = useState('45rem');

    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';

    const [drafts, setDrafts] = useState([]);
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        if (chatContainerRef.current && secondToLastMessageRef.current) {
            const chatContainerHeight = chatContainerRef.current.offsetHeight;
            const secondToLastMessageHeight = secondToLastMessageRef.current.offsetHeight;
            const newMinHeight = chatContainerHeight - secondToLastMessageHeight;
            const newMinHeightWithSubtraction = newMinHeight - 20;
            setMinHeightSubtracted(`${newMinHeightWithSubtraction}px`);
            setMinHeight(`${newMinHeight}px`); // Update the state with the new minimum height
        }
    }, [message]);

    useEffect(() => {
        const draftTags = [
            '<attempt_1_of_4>',
            '<attempt_2_of_4>',
            '<attempt_3_of_4>',
            '<attempt_4_of_4>',
        ];

        const updateDrafts = (msg) => {
            if (!msg) return;

            // Identify which draft tags are present in the message
            const presentDraftTags = draftTags.filter(tag => msg.includes(tag));

            if (presentDraftTags.length === 0 && drafts.length === 0) {
                // No draft tags found and no drafts exist yet
                setDrafts([msg]);
                return;
            }

            // Split the message based on the draft tags
            let draftContents = [];
            let lastIndex = 0;
            let isNewDraftStarted = false;
            presentDraftTags.forEach((tag, index) => {
                const tagIndex = msg.indexOf(tag, lastIndex);
                if (index === 0 && tagIndex > 0) {
                    // Handle content before the first tag
                    draftContents.push(msg.substring(0, tagIndex));
                }
                const nextTag = presentDraftTags[index + 1];
                const nextTagIndex = nextTag ? msg.indexOf(nextTag) : msg.length;
                draftContents.push(msg.substring(tagIndex, nextTagIndex));
                lastIndex = nextTagIndex;
            });

            // Check if a new draft has started
            isNewDraftStarted = draftContents.length > drafts.length;

            setDrafts(draftContents);

            // Only update the active tab if a new draft has started
            if (isNewDraftStarted) {
                setActiveTab(draftContents.length - 1);
            }
        };

        updateDrafts(message.response);
    }, [message.response]);



    const handleTabChange = (index) => {
        setActiveTab(index);
    };


    const whichMessage = (message) => {
        if (message.user === 'User') {
            if (message.question) {
                const formattedMessage = message.question;
                return <Markdown
                    remarkPlugins={[remarkGfm]}
                    components={markdownComponents}
                    children={formattedMessage} />
            } else {
                return <Markdown
                    remarkPlugins={[remarkGfm]}
                    components={markdownComponents}
                    children={message.question} />
            }
        } else if (message.user === 'Claude') {
            if (message.response) {
                const formattedMessage = message.response.replace(/\n{2,}/g, '\n&nbsp;\n')
                    // Replace single newline with a line break
                    .replace(/\n/g, '  \n');
                return <Markdown
                    remarkPlugins={[remarkGfm]}
                    components={markdownComponents}
                    children={formattedMessage} />
            } else {
                return <Markdown
                    remarkPlugins={[remarkGfm]}
                    components={markdownComponents}
                    children={message.response} />
            }
        }
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                toast({
                    title: "Copied!",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                })
            })
            .catch(err => {
                console.error('Could not copy text: ', err);
            });
    }


    if (message.user === "Claude" && message.response === "") {
        console.log('currentGenerationProgress', currentGenerationProgress)
        return (
            <Box
                key={message.id}
                bg={message.user.toLowerCase() === 'user' ? userBgColor : claudeBgColor}
                color={message.user.toLowerCase() === 'claude' ? claudeColor : lightTheme.colors.lightGray}
                alignSelf={message.user.toLowerCase() === 'user' ? 'flex-end' : 'flex-start'}
                py='1.5rem'
                px={['2rem','3rem', '5rem', '10rem', '15rem']}
                ref={isLastMessage ? lastMessageRef : null}
                width="100%"
                justifyContent={"flex-start"}
                alignItems={"center"}
                flexDirection={"row"}
                display={"flex"}
                minH={minHeight}
            >
                {currentGenerationProgress === -1 ?
                    <Lottie
                        animationData={lottieWriting}
                        loop={true}
                        autoplay={true}
                        style={{ width: '15rem', height: '15rem', backgroundColor:'white',}}
                    /> :
                    <VStack spacing={6}>
                        <Lottie
                            animationData={lottieWriting}
                            loop={true}
                            autoplay={true}
                            style={{ width: '15rem', height: '15rem', backgroundColor:'white',}}
                        />
                        <Text fontSize={18} fontWeight={'bold'}>
                            {currentGenerationProgress[0].toString() + '/' + currentGenerationProgress[1].toString() + " "}
                            <Text as="span" fontSize={16} fontWeight={'normal'}>
                                {t(' sources processed...')}
                            </Text>
                        </Text>
                    </VStack>}
            </Box>
        )
    }

    else {
        return (
            <Box
                key={message.id}
                bg={message.user.toLowerCase() === 'user' ? lightTheme.colors.lightGray : claudeBgColor}
                color={message.user.toLowerCase() === 'claude' ? claudeColor : 'black'}
                py='1.5rem'
                px={['2rem','3rem', '5rem', '10rem', '15rem']}
                ref={isLastMessage ? lastMessageRef : isSecondToLastMessage ? secondToLastMessageRef : null}
                width="100%"
                alignItems={"flex-start"}
                flexDirection={"row"}
                display={"flex"}
            >
                <Box display={'flex'} flexDirection={"column"} flex="1" minH={message.user.toLowerCase() === 'claude' && isLastMessage ?
                    minHeightSubtracted : '1rem'}>




                    {message.user === "Claude" && message.response.includes("<attempt_1_of_4>") ?
                        <Tabs index={activeTab} onChange={handleTabChange} variant='enclosed'>
                            <TabList>
                                {drafts.map((_, index) => (
                                    // Check if the current tab is the latest draft
                                    <Tab key={index}>{index === drafts.length - 1 ? t('Best Draft') : `Draft ${index + 1}`}</Tab>
                                ))}
                            </TabList>
                            <TabPanels>
                                {drafts.map((draft, index) => (
                                    <TabPanel key={index}>
                                        <Markdown
                                            remarkPlugins={[remarkGfm]}
                                            components={markdownComponents}
                                            children={draft.replace(/\n{2,}/g, '\n&nbsp;\n').replace(/\n/g, '  \n').replace(/<attempt_[1-4]_of_4>/g, '')} />
                                    </TabPanel>
                                ))}
                            </TabPanels>
                        </Tabs>
                        :
                        whichMessage(message)
                    }




                    <Box display={'flex'} flexDirection={"row"} alignItems={'center'}>
                    </Box>
                </Box>
            </Box>
        )
    }
}






